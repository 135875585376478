export enum ContentSquarePageTypes {
  HOMEPAGE = "HOMEPAGE",
  MY_ORDERS = "MY_ORDERS",
  ORDER_DETAILS = "ORDER_DETAILS",
  ORDER_RETURNS = "ORDER_RETURNS",
  MY_RETURNS = "MY_RETURNS",
  RETURN_DETAILS = "RETURN_DETAILS",
  DEFAULT = "MY_ACCOUNT",
}

export const getPageType = (pathname: string) => {
  let pageType: ContentSquarePageTypes;

  switch (true) {
    case pathname === "/my-account":
      pageType = ContentSquarePageTypes.HOMEPAGE;
      break;
    case pathname === "/my-account/orders":
      pageType = ContentSquarePageTypes.MY_ORDERS;
      break;
    case /^\/my-account\/orders\/[A-Za-z0-9]+$/.test(pathname):
      pageType = ContentSquarePageTypes.ORDER_DETAILS;
      break;
    case /^\/my-account\/orders\/[A-Za-z0-9]+\/return$/.test(pathname):
      pageType = ContentSquarePageTypes.ORDER_RETURNS;
      break;
    case pathname === "/my-account/returns":
      pageType = ContentSquarePageTypes.MY_RETURNS;
      break;
    case /^\/my-account\/returns\/[A-Za-z0-9]+$/.test(pathname):
      pageType = ContentSquarePageTypes.RETURN_DETAILS;
      break;
    default:
      pageType = ContentSquarePageTypes.DEFAULT;
      break;
  }

  return pageType;
};
