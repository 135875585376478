import React from "react";

import ButtonGroup from "@components/button-group";
import Modal from "@components/modal";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../../components/button";

import styles from "./index.css";

export interface DeleteAddressModalProps {
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
}

const DeleteAddressModal = ({
  isOpen,
  onAccept,
  onCancel,
}: DeleteAddressModalProps) => {
  const formatTranslation = useFormatTranslation();

  return (
    <Modal
      contentLabel={formatTranslation(
        "ma_web_addressbook_deleteaddress_deleteaddress"
      )}
      isOpen={isOpen}
      onRequestClose={onCancel}
      title={formatTranslation(
        "ma_web_addressbook_deleteaddress_deleteaddress"
      )}
      padContent
      type="center"
    >
      <div data-auto-id="DeleteAddressModal">
        <div className={styles.question}>
          {formatTranslation("ma_web_addressbook_deleteaddress_confirmation")}
        </div>
        <ButtonGroup flexDisplay centerContent>
          <Button
            onClick={() => onAccept()}
            type="button"
            data-auto-id="ConfirmDeleteAddressButton"
            size="large"
          >
            {formatTranslation("ma_web_addressbook_deleteaddress_delete")}
          </Button>
          <Button
            buttonType="secondary"
            onClick={() => onCancel()}
            data-auto-id="CancelDeleteAddressButton"
            type="button"
            size="large"
          >
            {formatTranslation("ma_web_addressbook_deleteaddress_cancel")}
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

export default DeleteAddressModal;
