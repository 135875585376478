import { createAction } from "redux-actions";

import { sendPageInteraction } from "@hooks/analytics/pageInteraction";

import { getLanguage, getCountry } from "@state/application/selectors";
import { silentTokenRefresh } from "@state/identity/actions";

import { isMockedEnvironment } from "@utils/mock-environment-checker";

import { customerLoyaltyOptIn, fetchLoyaltyStatus } from "./api";
import * as types from "./types";

export const apiError = createAction(types.API_ERROR);

export const retrieveLoyaltyStatus = createAction(types.GET_LOYALTY_DATA);
export const setLoyaltyData = createAction(types.SET_LOYALTY_DATA);
export const optCustomerIn = createAction(types.OPTING_CUSTOMER_IN);
export const toggleFirstView = createAction(types.TOGGLE_IS_FIRST_VIEW);
export const trackLoyaltyButtonClick = createAction(
  types.TRACK_LOYALTY_BUTTON_CLICK
);

export const getLoyaltyStatus =
  (postOptIn = false, getLoyaltyStatusFn = fetchLoyaltyStatus) =>
  async (dispatch, getState, identity) => {
    try {
      const state = getState();
      const lang = getLanguage(state);
      const country = getCountry(state);
      if (postOptIn || identity.customer.loyaltyTier || isMockedEnvironment()) {
        dispatch(retrieveLoyaltyStatus());
        const response = await getLoyaltyStatusFn(lang, country, identity);

        return dispatch(setLoyaltyData(response));
      }
      return;
    } catch (err) {
      return dispatch(apiError(err));
    }
  };

export const optCustomerIntoScheme =
  (customerLoyaltyOptInFn = customerLoyaltyOptIn) =>
  async (dispatch, getState, identity) => {
    dispatch(optCustomerIn());

    try {
      const state = getState();
      await dispatch(handleTrackLoyaltyButtonClick("join now"));
      const lang = getLanguage(state);
      const country = getCountry(state);
      await customerLoyaltyOptInFn(lang, country, identity);
      localStorage.setItem("loyaltyFirstView", true);
      await dispatch(silentTokenRefresh());
      return dispatch(getLoyaltyStatus(true));
    } catch (err) {
      return dispatch(apiError({ err, errorType: "optInError" }));
    }
  };

export const handleTrackLoyaltyButtonClick =
  (ctaName) => async (dispatch, getState) => {
    const state = getState();
    await sendPageInteraction(types.TRACK_LOYALTY_BUTTON_CLICK, state, {
      ctaName: ctaName,
    });
  };
