import { createAction } from "redux-actions";

import { getLanguage } from "@state/application/selectors";

import { getAssetAccessToken, getReturnedOrders } from "./api";
import * as types from "./types";

export const apiError = createAction(types.API_ERROR);

export const requestReturns = createAction(types.REQUEST_RETURNS);
export const receiveReturns = createAction(types.RECEIVE_RETURNS);

export const getReturns =
  (
    returnOptions = { isInitialRequest: false },
    getReturnedOrdersFn = getReturnedOrders
  ) =>
  async (dispatch, getState, identity) => {
    const state = getState();
    const {
      returnsHistory: { returns, loaded },
    } = state;
    const offset = returns.length;

    const loadMore = offset > 0;

    if (loaded && returnOptions.isInitialRequest) {
      return;
    }

    dispatch(requestReturns({ loadMore }));

    try {
      const response = await getReturnedOrdersFn(
        getLanguage(getState()),
        identity,
        offset
      );
      return dispatch(receiveReturns(response));
    } catch (err) {
      err.userAction = "getReturns";
      dispatch(apiError(err));
      return dispatch(receiveReturns(err));
    }
  };

export const clickDownloadReturnAsset = createAction(
  types.CLICK_DOWNLOAD_RETURN_ASSET
);
export const requestAssetAccessToken = createAction(
  types.REQUEST_ASSET_ACCESS_TOKEN
);
export const receiveAssetAccessToken = createAction(
  types.RECEIVE_ASSET_ACCESS_TOKEN
);

export const downloadReturnAsset =
  (
    returnReference,
    returnDocumentUri,
    handleAsset,
    getAssetAccessTokenFn = getAssetAccessToken
  ) =>
  async (dispatch, getState, identity) => {
    try {
      dispatch(clickDownloadReturnAsset());
      dispatch(requestAssetAccessToken(returnReference));

      const { documentUri, returnDocumentUri: returnDocumentWithAccessToken } =
        await getAssetAccessTokenFn(
          returnReference,
          returnDocumentUri,
          identity
        );

      /*
      The Access Token API is returning `documentUri` while the contract states it should be called `returnDocumentUri`.
      This code will work with either.
    */
      handleAsset(documentUri || returnDocumentWithAccessToken);
      dispatch(receiveAssetAccessToken());
    } catch (e) {
      dispatch(receiveAssetAccessToken(e));
    }
  };
