import React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";
import PropTypes from "prop-types";

import Icon from "@components/picture-icon";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { getNumericalCurrentTierId } from "@state/loyalty/selectors";

import AppleStore from "./images/apple-store.svg";
import DigitalStylistQRCode from "./images/digital-stylist-qrcode.svg";
import EarlyAccessToExclusivesQRCode from "./images/early-access-to-exclusives-qrcode.svg";
import EarlyAccessToSaleQRCode from "./images/early-access-to-sale-qrcode.svg";
import GooglePlayStore from "./images/google-play.png";
import MembersOnlySaleQRCode from "./images/members-only-sale-qrcode.svg";
import QRCode from "./images/qrCode.png";

import styles from "./index.css";

export const AppBanner = ({ benefitType }) => {
  const formatTranslation = useFormatTranslation();
  const currentTierId = useSelector((state: any) =>
    getNumericalCurrentTierId(state)
  );
  const classes = classNames(styles.wrapper, {
    [styles.tier1]: currentTierId === 1,
    [styles.tier2]: currentTierId === 2,
    [styles.tier3]: currentTierId === 3,
    [styles.tier4]: currentTierId === 4,
  });

  const usingiOSDevice = () => {
    return /(iphone|ipad)/i.test(navigator.userAgent);
  };

  const qrCodeMap = {
    DigitalStylist: DigitalStylistQRCode,
    EarlyAccessToExclusives: EarlyAccessToExclusivesQRCode,
    EarlyAccessToSale: EarlyAccessToSaleQRCode,
    MembersOnlySale: MembersOnlySaleQRCode,
  };

  return (
    <div className={classes}>
      <div>
        <Icon id="loyaltyWhite" />
        <h3>{formatTranslation("ma_web_loyalty_hub_app_exclusive")}</h3>
        <p>
          {formatTranslation("ma_web_loyalty_hub_app_exclusive_description")}
        </p>
      </div>
      <img
        className={styles.qrCode}
        src={qrCodeMap[benefitType] ? qrCodeMap[benefitType] : QRCode}
        alt="ASOS App QR Code"
      />
      <div className={styles.storeBadges}>
        {usingiOSDevice() ? (
          <a
            id="apply-store"
            href="https://apps.apple.com/gb/app/asos/id457876088"
          >
            <img src={AppleStore} alt="Apple Store Badge" />
          </a>
        ) : (
          <a
            id="google-play"
            href="https://play.google.com/store/apps/details?id=com.asos.app"
          >
            <img src={GooglePlayStore} alt="Google Play Badge" />
          </a>
        )}
      </div>
    </div>
  );
};

AppBanner.propTypes = {
  benefitType: PropTypes.string.isRequired,
};
