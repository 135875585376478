import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";

import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

type NotificationAreaProps = {
  notification?: {
    id: number;
    textKey: string;
    textValues: Record<any, any>;
    type: string;
  };
  dismissNotification: () => void;
};

const NotificationArea: React.FC<NotificationAreaProps> = ({
  dismissNotification,
  notification = null,
}) => {
  const formatTranslation = useFormatTranslation();

  return (
    <div className={styles.notificationArea} data-auto-id="NotificationArea">
      <TransitionGroup>
        {notification && (
          <CSSTransition
            classNames={{
              appear: styles.notificationAppear,
              appearActive: styles.notificationAppearActive,
              enter: styles.notificationAppear,
              enterActive: styles.notificationAppearActive,
              exit: styles.notificationLeave,
              exitActive: styles.notificationLeaveActive,
            }}
            timeout={{ enter: 1000, exit: 500 }}
          >
            <NotificationBar
              key={notification.id}
              type={notification.type}
              onClose={dismissNotification}
              closeButtonAriaLabel={formatTranslation("ma_web_modal_close")}
            >
              <Translation
                id={notification.textKey}
                values={notification.textValues}
              />
            </NotificationBar>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default NotificationArea;
