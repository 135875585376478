export const getDocumentAttribute = (attribute, root = document) => {
  const element = root.documentElement;
  if (element) {
    return element.getAttribute(attribute);
  }
  return null;
};

export const getKeyStoreDataversion = (pageDocument = document) =>
  getDocumentAttribute("data-key-store-data-version", pageDocument);

export const getApplicationVersion = (pageDocument = document) =>
  getDocumentAttribute("data-application-version", pageDocument);

export const getApiResponseTimeout = (pageDocument = document) =>
  getDocumentAttribute("data-api-response-timeout", pageDocument);

export const getPageLanguage = (pageDocument = document) =>
  getDocumentAttribute("lang", pageDocument);

export const getPageECommerceStore = (pageDocument = document) =>
  getDocumentAttribute("data-store", pageDocument);

export const getPagePlatform = (pageDocument = document) =>
  getDocumentAttribute("data-platform", pageDocument);

export const getPageCountry = (pageDocument = document) =>
  getDocumentAttribute("data-country", pageDocument).toUpperCase();

export const getPageCurrency = (pageDocument = document) =>
  getDocumentAttribute("data-currency", pageDocument);

export const getPageShop = (pageDocument = document) =>
  getDocumentAttribute("data-shop", pageDocument);

export const getPageSiteRoot = (pageDocument = document) =>
  `https://${getDocumentAttribute("data-site", pageDocument)}`;
