import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import Modal from "@components/modal";

import { getIfMobilePlatform } from "@state/application/selectors";

import BenefitDetails from "../benefit-details";

const LockedModal = ({
  isOpen,
  setIsOpen,
  lockedBenefit,
  title,
  birthdayTileNoDOB,
  benefitTier,
}) => {
  useEffect(() => {
    const lockedBenefitToOpen = localStorage.getItem("lockedBenefitToOpen");
    if (lockedBenefit === lockedBenefitToOpen) {
      localStorage.removeItem("lockedBenefitToOpen");
    }
  }, []);
  const isMobile = useSelector((state) => getIfMobilePlatform(state));

  return (
    <Modal
      contentLabel={title}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      title={title}
      type={isMobile ? "bottom" : "right"}
      padContent={false}
    >
      <BenefitDetails
        lockedBenefit={lockedBenefit}
        birthdayTileNoDOB={birthdayTileNoDOB}
        benefitTier={benefitTier}
      />
    </Modal>
  );
};

LockedModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  lockedBenefit: PropTypes.string,
  birthdayTileNoDOB: PropTypes.bool,
  benefitTier: PropTypes.number,
};

LockedModal.defaultProps = {
  lockedBenefit: "",
  birthdayTileNoDOB: false,
  benefitTier: 0,
};

export default LockedModal;
