import { connect } from "react-redux";

import appConfig from "@appConfig";

import { setMenuClicked } from "@state/application/actions";
import { getSegments } from "@state/audience-segments/selectors";
import { logout } from "@state/identity/actions";
import { canChangePassword } from "@state/identity/selectors";
import { getIsOptedIn } from "@state/loyalty/selectors";
import {
  hasPremierSubscription,
  getPremierDaysRemaining,
  getLatestUserSubscription,
} from "@state/premier/selectors";
import {
  hasSubscriptionOptions,
  getExpiringSoonThresholdDays,
} from "@state/subscription-options/selectors";

import Navigation from "./components";
import navGroups from "./nav-groups.json";
import navGroupsV2 from "./nav-groupsV2.json";

export const shouldShowNavItem = ({
  premierIsAnOption,
  premierStatus = {},
  changePasswordEnabled,
  loyaltyIsAnOption,
} = {}) => {
  const hideConditions = {
    "no-change-password": !changePasswordEnabled,
    "no-premier": !(premierStatus.isActive || premierIsAnOption),
    "no-returns": !appConfig.returnsEnabled,
    "no-loyalty": !appConfig.loyaltyHubEnabled || !loyaltyIsAnOption,
  };

  return ({ hiddenCondition }) => !(hideConditions[hiddenCondition] || false);
};

const isPremierNavItem = ({ hiddenCondition }) =>
  hiddenCondition === "no-premier";

const getPremierTagText = ({ isActive, isExpiring }) => {
  const statuses = "ma_web_premierdelivery_status";
  if (isExpiring) {
    return `${statuses}_expiring`;
  } else if (isActive) {
    return `${statuses}_active`;
  }
  return null;
};

const setTagText = (premierStatus) => (item) => {
  if (isPremierNavItem(item)) {
    return {
      ...item,
      tagText: getPremierTagText(premierStatus),
    };
  }
  return item;
};

const mapStateToProps = (state) => {
  const isActive = hasPremierSubscription(state);
  const remainingDays = getPremierDaysRemaining(state);
  const thresholdDays = getExpiringSoonThresholdDays(state);
  const latestUserSubscription = getLatestUserSubscription(state);
  const isPremierAutoRenewal =
    latestUserSubscription?.instructionType == "Recurring";
  const isExpiring =
    isActive && remainingDays <= thresholdDays && !isPremierAutoRenewal;

  const premierStatus = {
    isActive,
    isExpiring,
  };

  const segments = getSegments(state);
  const isOptedIn = getIsOptedIn(state);

  const tagTextMapping = setTagText(premierStatus);

  const isLoyaltyAvailableToCustomer = () => {
    const { loyaltyInviteOnly } = appConfig;
    if (isOptedIn) {
      return true;
    }
    if (loyaltyInviteOnly) {
      return Boolean(
        segments?.find(
          (segment) =>
            segment.segmentId === appConfig.loyaltyInviteOnlySegmentId
        )
      );
    }

    return true;
  };

  const itemFilter = shouldShowNavItem({
    premierIsAnOption: hasSubscriptionOptions(state),
    premierStatus,
    changePasswordEnabled: canChangePassword(state),
    loyaltyIsAnOption: isLoyaltyAvailableToCustomer(),
  });

  const navigationVersion = appConfig?.newNavigation ? navGroupsV2 : navGroups;

  return {
    navGroups: navigationVersion.map(({ items, ...rest }) => ({
      ...rest,
      items: items.filter(itemFilter).map(tagTextMapping),
    })),
  };
};

const mapDispatchToProps = {
  setMenuClicked,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
