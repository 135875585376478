import React from "react";
import { useSelector, useDispatch } from "react-redux";

import classNames from "classnames";
import PropTypes from "prop-types";

import NotificationArea from "@components/notification-area";
import ScrollToTop from "@components/scroll-to-top";

import { useThemeContext } from "@context/ThemeContext";

import { dismissNotification as dismissNotificationAction } from "@state/application/actions";

import styles from "./index.css";

const StickySection = ({ showScrollToTop }) => {
  const { notification } = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const dismissNotification = () => dispatch(dismissNotificationAction());
  const { isDarkMode } = useThemeContext();

  return (
    <div
      className={classNames(styles.stickySection, {
        [styles.dark]: isDarkMode,
      })}
    >
      {showScrollToTop && (
        <div className={styles.stickyItemWrapper}>
          <ScrollToTop />
        </div>
      )}
      <div className={styles.stickyItemWrapper}>
        <NotificationArea
          notification={notification}
          dismissNotification={dismissNotification}
        />
      </div>
    </div>
  );
};

StickySection.propTypes = {
  showScrollToTop: PropTypes.bool,
};

StickySection.defaultProps = {
  showScrollToTop: false,
};

export default StickySection;
