import { createAction } from "redux-actions";

import { notifyCustomerId } from "@utils/monitoring";

import { receiveCurrentLoggedInProvider } from "../social-accounts/actions";
import { decodeJWT } from "./jwt";
import { getLoaded } from "./selectors";
import { REQUEST_IDENTITY_PROFILE, RECEIVE_IDENTITY_PROFILE } from "./types";

export const requestIdentityProfile = createAction(REQUEST_IDENTITY_PROFILE);
export const receiveIdentityProfile = createAction(RECEIVE_IDENTITY_PROFILE);

export const authenticateIdentity =
  () => async (dispatch, getState, identity) =>
    identity.isAuthenticated();

export const login = () => async (dispatch, getState, identity) =>
  identity.login();

export const logout = () => async (dispatch, getState, identity) =>
  identity.logout();

export const silentTokenRefresh = () => async (dispatch, getState, identity) =>
  identity.silentTokenRefresh();

export const getIdentityProfile =
  () => async (dispatch, getState, identity) => {
    const state = getState();

    if (getLoaded(state)) {
      return null;
    }

    dispatch(requestIdentityProfile());

    try {
      const [
        rawAccessToken,
        customerGuid,
        customerId,
        homeCountry,
        lastAction,
        identityProvider,
      ] = await Promise.all([
        identity.getAccessToken(),
        identity.customer.customerGuid,
        identity.customer.customerId,
        identity.customer.homeCountry,
        identity.customer.lastAction,
        identity.customer.identityProvider,
      ]);
      const response = {
        customerGuid,
        customerId,
        homeCountry,
        lastAction,
        identityProvider,
        accessToken: decodeJWT(rawAccessToken),
      };
      notifyCustomerId(customerId, customerGuid);

      dispatch(
        receiveCurrentLoggedInProvider({
          loginProvider: response.accessToken.idp,
        })
      );

      return dispatch(receiveIdentityProfile(response));
    } catch (err) {
      return dispatch(receiveIdentityProfile(err));
    }
  };
