import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export const StatusTag = ({
  tag,
  tagCount,
  displayOnTile,
}: {
  tag: string;
  tagCount: number;
  displayOnTile: boolean;
}) => {
  const formatTranslation = useFormatTranslation();

  return (
    <span
      className={classNames(styles.wrapper, {
        [styles.tileTag]: displayOnTile,
      })}
    >
      {tagCount > 0
        ? formatTranslation(tag, {
            count: tagCount,
          })
        : formatTranslation(tag)}
    </span>
  );
};

StatusTag.propTypes = {
  tag: PropTypes.string.isRequired,
  tagCount: PropTypes.number,
  displayOnTile: PropTypes.bool,
};

StatusTag.defaultProps = {
  tagCount: 0,
  displayOnTile: false,
};
