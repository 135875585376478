import { format } from "date-fns";

import request from "asos.myaccount.request";

const CUSTOMER_ENDPOINT = "customer/profile/v2/customers/{customerId}";

export const getUser = (identity, requestFn = request) =>
  requestFn(CUSTOMER_ENDPOINT, identity);

export const updateUser = (userDetails, identity, requestFn = request) =>
  requestFn(CUSTOMER_ENDPOINT, identity, {
    body: JSON.stringify({
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      gender: userDetails.gender,
      emailAddress: userDetails.emailAddress,
      dateOfBirth: format(
        new Date(
          userDetails.dateOfBirth.year,
          userDetails.dateOfBirth.month,
          userDetails.dateOfBirth.day
        ),
        "yyyy-MM-dd"
      ),
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "PATCH",
  });
