import React, { useEffect, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { StatusBar } from "@asosteam/asos-web-component-library-status-bar";

import { Translation } from "@components/translation";

import { useThemeContext } from "@context/ThemeContext";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import CharmsTier1 from "./images/charms-tier-1.png";
import CharmsTier2 from "./images/charms-tier-2.png";
import CharmsTier3 from "./images/charms-tier-3.png";
import CharmsTier4 from "./images/charms-tier-4.png";

import styles from "./index.css";

export interface TierCardProps {
  tierId: number;
}

export const TierCard = ({ tierId }: TierCardProps) => {
  const { isDarkMode } = useThemeContext();
  const [tierPercent, setTierPercent] = useState(0);
  const classes = classNames(styles.wrapper, {
    [styles.tier1]: tierId === 1,
    [styles.tier2]: tierId === 2,
    [styles.tier3]: tierId === 3,
    [styles.tier4]: tierId === 4,
    [styles.cardBorder]: isDarkMode && tierId === 4,
  });
  const formatTranslation = useFormatTranslation();

  const charms = [CharmsTier1, CharmsTier2, CharmsTier3, CharmsTier4];

  useEffect(() => {
    setTierPercent((tierId / 4) * 100);
  }, []);

  const formatAriaLabel = (tier: number) => {
    const tierString = formatTranslation("ma_web_loyalty_hub_tier");
    const tierProgressString =
      tier <= tierId
        ? formatTranslation("ma_web_loyalty_tier_progress_complete")
        : "";
    return `${tierString} ${tier}${
      tierProgressString ? ` ${tierProgressString}` : ""
    }`;
  };

  return (
    <div className={classes}>
      <div>
        <h2>
          <Translation id="ma_web_loyalty_hub_tier" /> 0{tierId}
        </h2>
        <h1>
          <Translation id={`ma_web_loyalty_hub_tier_${tierId}`} />
        </h1>
      </div>
      <img
        src={charms[tierId - 1]}
        className={styles.charms}
        alt={formatTranslation(`ma_web_loyalty_hub_tier_${tierId}`)}
      />
      <div
        className={styles.statusBarContainer}
        aria-label={formatTranslation(
          "ma_web_loyalty_tier_progress_description"
        )}
      >
        <StatusBar
          statusBarPercentage={tierPercent}
          statusBarColor={tierId === 4 ? "rgba(111, 92, 255, 1)" : "black"}
        />
        <div className={styles.tierLabelsWrapper}>
          <div className={styles.tierLabels}>
            {[1, 2, 3, 4].map((tier) => (
              <span
                key={tier}
                className={styles[`tier${tier}Label`]}
                aria-label={formatAriaLabel(tier)}
              >
                <Translation id="ma_web_loyalty_hub_tier" /> {tier}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

TierCard.propTypes = {
  tierId: PropTypes.number.isRequired,
};
