import React, { useState } from "react";

import { CardSection } from "@asosteam/asos-web-component-library-cards";

import FieldLabel from "@components/field-label";
import Icon from "@components/picture-icon";
import StoreAddress from "@components/store-address";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../../../components/button";
import CollectionPointModal from "../collection-point-modal";

import styles from "./index.css";

export interface StoreDetailsProps {
  dataAutoId?: string;
  countryCode: string;
  provider: {
    logoUri: string;
    name: string;
  };
  resetReturnLocation: () => void;
  store: {
    address: {
      address1: string;
      address2: string | null;
      address3: string | null;
      locality: string;
      postalCode: string;
    };
    distanceInMeters: number;
    distanceInMiles: number;
    name: string;
    tradingPeriods: {
      day: string;
      weekDayIndex: number;
      openingHours: string;
    }[];
  };
}

const StoreDetails = ({
  dataAutoId = undefined,
  countryCode,
  provider,
  resetReturnLocation,
  store,
}: StoreDetailsProps) => {
  const [showModal, setShowModal] = useState(false);
  const formatTranslation = useFormatTranslation();
  const isGB = countryCode === "GB";

  const toggleModal = () => {
    setShowModal(true);
  };

  return (
    <CardSection>
      <div className={styles.wrapper} data-auto-id={dataAutoId}>
        <div className={styles.heading}>
          <div className={styles.text}>
            <div className={styles.label}>
              <FieldLabel
                text={formatTranslation(
                  "ma_web_orderreturn_returnmethod_dropoff_selectedheading"
                )}
                inline
              />
            </div>
          </div>
        </div>
        <div className={styles.detail}>
          <div className={styles.text}>
            <div className={styles.name}>
              <img
                alt={provider.name}
                className={styles.icon}
                role="presentation"
                src={provider.logoUri}
              />
              {store.name}
              {isGB && (
                <button
                  type="button"
                  className={styles.infoButton}
                  onClick={toggleModal}
                  data-testid="info-button"
                >
                  <Icon id="info" size="small" />
                </button>
              )}
            </div>
            <div className={styles.address}>
              <StoreAddress address={store.address} />
            </div>
            <p className={styles.carrierInfo}>
              {formatTranslation("returns_carrier_info", {
                courier: provider.name,
              })}
            </p>
          </div>
          <div className={styles.button}>
            <Button
              data-auto-id="changeDropOffLocation"
              buttonType="tertiary"
              onClick={resetReturnLocation}
              className={styles.innerButton}
            >
              {formatTranslation("ma_web_orderreturn_returnmethod_change")}
            </Button>
          </div>

          <CollectionPointModal
            formatTranslation={formatTranslation}
            store={store}
            provider={provider}
            showModal={showModal}
            toggleModal={setShowModal}
          />
        </div>
      </div>
    </CardSection>
  );
};

export default StoreDetails;
