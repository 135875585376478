import React from "react";

import { Button as BaseButton } from "@asosteam/asos-web-component-library-button";

import { SmartLink } from "../smart-link";

import Styles from "./index.css";

export interface ButtonProps {
  buttonType?:
    | "primary"
    | "secondary"
    | "highlighted"
    | "primary-purchase"
    | "tertiary"
    | "primary-asos-world"
    | "secondary-asos-world";
  children: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  iconSrc?: string;
  onClick?: () => void;
  size?: "x-large" | "large" | "default" | "small" | "x-small";
  urlParameters?: Record<string, string>;
  to?: string;
  isInProgress?: boolean;
  [rest: string]: any;
}

export const Button = ({
  buttonType = "primary",
  disabled = false,
  error = null,
  iconSrc = null,
  onClick = undefined,
  size = "large",
  children,
  to = null,
  urlParameters = null,
  isInProgress = false,
  ...rest
}: ButtonProps) => {
  const isLink = typeof to === "string";
  // urlParameters isn't valid on button element.
  // Prop spreading attempts to pass default value of urlParameters
  // to button element or any element override (in this case only
  // SmartLink). Unfortunately have to split the return into two
  // statements in order to avoid it being spread and not break current
  // functionality
  if (isLink) {
    return (
      <BaseButton
        buttonType={buttonType}
        disabled={disabled}
        error={error}
        iconSrc={iconSrc}
        onClick={onClick}
        size={size}
        isInProgress={isInProgress}
        className={Styles["button-wrapper"]}
        elementOverride={SmartLink}
        urlParameters={urlParameters}
        to={to}
        {...rest}
      >
        {children}
      </BaseButton>
    );
  }

  return (
    <BaseButton
      buttonType={buttonType}
      disabled={disabled}
      error={error}
      iconSrc={iconSrc}
      onClick={onClick}
      size={size}
      isInProgress={isInProgress}
      className={Styles["button-wrapper"]}
      to={to}
      {...rest}
    >
      {children}
    </BaseButton>
  );
};
