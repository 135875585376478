import React from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";
import PropTypes from "prop-types";

import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";

import Icon from "@components/picture-icon";

import { useThemeContext } from "@context/ThemeContext";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import PremierHeader from "../../app/premier/premier-delivery/components/header-section";
import { Button } from "../../components/button";
import baseStyles from "../../index.css";
import backHomeIconDarkMode from "./img/icon-back-home-dark-mode.svg";
import backHomeIcon from "./img/icon-back-home.svg";
import backIcon from "./img/icon-back.svg";
import closeIcon from "./img/icon-close.svg";
import PageHeaderTitle from "./page-header-title";

import styles from "./component.css";

const PageHeader = ({
  buttonOnClick,
  buttonOnRight,
  buttonText,
  buttonTo,
  dataAutoId,
  description,
  descriptionAlwaysVisible,
  descriptionType,
  descriptionTranslated,
  detached,
  hasBgImage,
  hasBottomPadding,
  hasTabs,
  headingImage,
  icon,
  isChromeless,
  isPremierPage,
  previousPageLink,
  title,
  type,
  isLoyalty,
  titleCenterAlign,
}) => {
  const formatTranslation = useFormatTranslation();
  const { isDarkMode } = useThemeContext();
  if (isPremierPage) {
    return (
      <PremierHeader
        dataAutoId={dataAutoId}
        hasBgImage={hasBgImage}
        headingImage={headingImage}
        previousPageLink={previousPageLink}
        title={title}
      />
    );
  }

  const buttonCheck = buttonText && (buttonTo || buttonOnClick);
  const buttonWrapper = () => (
    <div
      className={
        buttonOnRight ? styles.rightheadingButton : styles.headingButton
      }
    >
      <Button
        buttonType="secondary"
        data-auto-id={`${dataAutoId || "PageHeader"}Button`}
        to={buttonTo}
        onClick={buttonOnClick}
      >
        {formatTranslation(buttonText)}
      </Button>
    </div>
  );

  return (
    <div
      data-auto-id={dataAutoId}
      className={classnames({ [styles.loyaltyPageHeader]: isLoyalty })}
    >
      <div
        className={classnames(styles.pageHeading, {
          [baseStyles.darkModeAsosLayer1]: isDarkMode,
          [styles.detached]: detached,
          [styles.bottomPadding]: hasBottomPadding,
          [styles.primary]: type === "primary",
          [styles.withButton]: buttonText && buttonTo && !buttonOnRight,
          [styles.withInfo]: description && descriptionType,
          [styles.withTabs]: hasTabs,
        })}
      >
        <span
          className={classnames({
            [styles.iconGroup]: true,
            [styles.chromeless]: isChromeless,
          })}
        >
          <Link
            to={previousPageLink}
            className={styles.showOnSmall}
            data-auto-id="mobile-back-link"
          >
            {type === "primary" ? (
              <img
                src={isDarkMode ? backHomeIconDarkMode : backHomeIcon}
                alt={formatTranslation("ma_web_navigation_backtomyaccount")}
                className={styles.icon}
              />
            ) : (
              <img
                src={isChromeless ? closeIcon : backIcon}
                alt={formatTranslation("ma_web_navigation_backtopreviouspage")}
                className={styles.icon}
              />
            )}
          </Link>
          {type === "primary" && icon && (
            <span className={styles.largePageIcon}>
              <Icon id={icon} size="large" />
            </span>
          )}
        </span>

        <div className={styles.content}>
          <PageHeaderTitle
            hasDescription={!!description}
            title={title}
            type={type}
            alignCenter={titleCenterAlign}
          />
          {buttonCheck && buttonOnRight && buttonWrapper()}
        </div>
        {description && (
          <Paragraph
            className={classnames(styles.description, {
              [styles.hideOnSmall]: !descriptionAlwaysVisible,
              [styles[descriptionType]]: descriptionType,
            })}
          >
            {descriptionTranslated
              ? description
              : formatTranslation(description)}
          </Paragraph>
        )}
      </div>

      {buttonCheck && !buttonOnRight && buttonWrapper()}
    </div>
  );
};

PageHeader.defaultProps = {
  buttonOnClick: null,
  buttonText: null,
  buttonTo: null,
  dataAutoId: null,
  description: null,
  descriptionAlwaysVisible: false,
  descriptionType: null,
  descriptionTranslated: false,
  detached: false,
  hasBgImage: false,
  hasBottomPadding: false,
  hasTabs: false,
  headingImage: null,
  icon: null,
  isChromeless: false,
  isPremierPage: false,
  previousPageLink: "/",
  title: "",
  type: "primary",
  isLoyalty: false,
  titleCenterAlign: false,
};

PageHeader.propTypes = {
  buttonOnClick: PropTypes.func,
  buttonText: PropTypes.string,
  buttonTo: PropTypes.string,
  dataAutoId: PropTypes.string,
  description: PropTypes.string,
  descriptionAlwaysVisible: PropTypes.bool,
  descriptionType: PropTypes.string,
  descriptionTranslated: PropTypes.bool,
  detached: PropTypes.bool,
  hasBgImage: PropTypes.bool,
  hasBottomPadding: PropTypes.bool,
  isLoyalty: PropTypes.bool,
  hasTabs: PropTypes.bool,
  headingImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  icon: PropTypes.string,
  isChromeless: PropTypes.bool,
  isPremierPage: PropTypes.bool,
  previousPageLink: PropTypes.string,
  buttonOnRight: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["primary", "secondary"]),
  titleCenterAlign: PropTypes.bool,
};

export default PageHeader;
