import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import classnames from "classnames";
import PropTypes from "prop-types";

import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";

import { Button } from "@components/button";
import Icon from "@components/picture-icon";
import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { clearApiErrors } from "@state/application/actions";
import {
  handleTrackLoyaltyButtonClick,
  optCustomerIntoScheme,
  toggleFirstView,
} from "@state/loyalty/actions";
import {
  getNumericalCurrentTierId,
  getHasOptInError,
  getIsFirstView,
} from "@state/loyalty/selectors";

import styles from "./index.css";

export const LoyaltyNavLink = ({ isLoyaltyMember }) => {
  const formatTranslation = useFormatTranslation();
  const isFirstView = useSelector((state) => getIsFirstView(state));
  const currentTierId = useSelector((state) =>
    getNumericalCurrentTierId(state)
  );
  const optInError = useSelector((state) => getHasOptInError(state));
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCustomerOptIn = async () => {
    dispatch(clearApiErrors());
    setInProgress(true);
    await dispatch(optCustomerIntoScheme());
    setInProgress(false);
  };

  useEffect(() => {
    const newMember = localStorage.getItem("loyaltyFirstView");
    if (newMember && isLoyaltyMember) {
      dispatch(toggleFirstView(true));
      navigate("/asos-world");
    }
  }, [isLoyaltyMember]);

  useEffect(() => {
    if (isFirstView && !localStorage.getItem("loyaltyFirstView")) {
      dispatch(toggleFirstView(false));
    }
  }, [location]);

  const replaceJSX = (string, findArray, replaceArray) => {
    let result = [string];

    findArray.forEach((find, index) => {
      const replace = replaceArray[index];
      result = result.flatMap((item) =>
        typeof item === "string"
          ? item
              .split(find)
              .flatMap((part, i, arr) =>
                i < arr.length - 1 ? [part, replace] : [part]
              )
          : [item]
      );
    });

    return result;
  };

  return (
    <div
      className={classnames({
        [styles.wrapper]: true,
        [styles.optedOut]: !isLoyaltyMember,
        [styles.firstView]: isFirstView,
      })}
    >
      <div className={styles.iconWrapper}>
        {!isLoyaltyMember && (
          <span className={styles.pill}>
            <Translation id="ma_web_loyalty_hub_nav_item_pill" />
          </span>
        )}
        <Icon id="loyalty" />
        {currentTierId && isLoyaltyMember && (
          <div className={styles.tierBadge}>
            <Icon
              id={`loyaltyTier${currentTierId}`}
              alt={`${formatTranslation(
                "ma_web_loyalty_hub_tier"
              )} ${currentTierId}`}
            />
            <Translation id={`ma_web_loyalty_hub_tier_${currentTierId}`} />
          </div>
        )}
      </div>
      <div className={styles.text}>
        {!isLoyaltyMember ? (
          <p>
            <Translation id="ma_web_loyalty_hub_nav_item_desc_opted_out" />
          </p>
        ) : (
          <Translation id="ma_web_loyalty_hub_nav_item_desc" />
        )}
      </div>
      {isLoyaltyMember && (
        <Button
          className={styles.loyaltyCtaSecondary}
          size="default"
          buttonType="secondary-asos-world"
          onClick={() => {
            dispatch(handleTrackLoyaltyButtonClick("view my benefits"));
          }}
        >
          <Translation id="ma_web_loyalty_hub_nav_item_cta" />
        </Button>
      )}
      {!isLoyaltyMember && (
        <>
          <Button
            className={styles.loyaltyCtaPrimary}
            size="default"
            buttonType="primary-asos-world"
            onClick={() => handleCustomerOptIn()}
            isInProgress={inProgress}
            disabled={inProgress}
          >
            <Translation id="ma_web_loyalty_hub_nav_item_cta_join_now" />
          </Button>
          {optInError && (
            <div
              className={classnames(styles.optInError, {
                [styles.optInErrorActive]: optInError,
              })}
            >
              <NotificationBar type="error" hasIcon={false}>
                <Translation id="ma_web_loyalty_hub_nav_item_cta_join_now_error" />
              </NotificationBar>
            </div>
          )}
          <Button
            className={styles.loyaltyCtaSecondary}
            size="default"
            buttonType="secondary-asos-world"
            onClick={() => {
              dispatch(handleTrackLoyaltyButtonClick("learn more"));
              window.open("https://www.asos.com/discover/asos-world", "_self");
            }}
          >
            <Translation id="ma_web_loyalty_hub_nav_item_faq_link" />
          </Button>
          <div className={styles.tsAndCsWrapper}>
            {replaceJSX(
              formatTranslation("ma_web_loyalty_hub_nav_item_terms_link"),
              ["%{termslink}", "%{privacypolicylink}"],
              [
                <Button
                  key="termslink"
                  className={styles.tsAndCs}
                  size="small"
                  buttonType="secondary"
                  onClick={() => {
                    dispatch(handleTrackLoyaltyButtonClick("t&cs"));
                    window.open(
                      "https://www.asos.com/terms-and-conditions/",
                      "_blank"
                    );
                  }}
                >
                  {formatTranslation(
                    "ma_web_loyalty_hub_nav_item_terms_link_text"
                  )}
                </Button>,
                <Button
                  key="privacypolicylink"
                  className={styles.tsAndCs}
                  size="small"
                  buttonType="secondary"
                  onClick={() => {
                    dispatch(handleTrackLoyaltyButtonClick("privacy policy"));
                    window.open(
                      "https://www.asos.com/privacy-policy/",
                      "_blank"
                    );
                  }}
                >
                  {formatTranslation(
                    "ma_web_loyalty_hub_nav_item_policy_link_text"
                  )}
                </Button>,
              ]
            )}
          </div>
        </>
      )}
    </div>
  );
};

LoyaltyNavLink.propTypes = {
  isLoyaltyMember: PropTypes.bool,
};

LoyaltyNavLink.defaultProps = {
  isLoyaltyMember: false,
};
