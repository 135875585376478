import { isWebView } from "../utilities";

const getEarlyAccessToNewInUrl = (gender) => {
  const useDeepLlink = isWebView();
  const womensNewInUrl = useDeepLlink
    ? "asos://category?cid=51163"
    : "https://www.asos.com/women/new-in/new-in-today/cat/?cid=51163";
  const mensNewInUrl = useDeepLlink
    ? "asos://category?cid=51164"
    : "https://www.asos.com/men/new-in/new-in-today/cat/?cid=51164";
  return gender === "M" ? mensNewInUrl : womensNewInUrl;
};

export const benefitConfig = {
  "birthday-discount": {
    infoTitle: "ma_web_loyalty_birthday_discount_info_title",
    infoDescription: "ma_web_loyalty_birthday_discount_info_description",
    howToUse: [
      "ma_web_loyalty_birthday_discount_how_to_use_1",
      "ma_web_loyalty_birthday_discount_how_to_use_2",
      "ma_web_loyalty_birthday_discount_how_to_use_3",
    ],
  },
  "digital-stylist": {
    infoTitle: "ma_web_loyalty_digital_stylist_info_title",
    infoDescription: "ma_web_loyalty_digital_stylist_info_description",
    howToUse: [
      "ma_web_loyalty_digital_stylist_how_to_use_1",
      "ma_web_loyalty_digital_stylist_how_to_use_2",
      "ma_web_loyalty_digital_stylist_how_to_use_3",
    ],
    ctaText: "ma_web_loyalty_digital_stylist_cta_text",
    ctaUrl: "asos://ai-stylist",
  },
  "early-access-to-new-in": {
    infoTitle: "ma_web_loyalty_early_access_to_new_in_info_title",
    infoDescription: "ma_web_loyalty_early_access_to_new_in_info_description",
    howToUse: ["ma_web_loyalty_early_access_to_new_in_how_to_use_1"],
    ctaText: "ma_web_loyalty_early_access_to_new_in_cta_text",
    ctaUrlFn: getEarlyAccessToNewInUrl,
  },
  "early-access-to-exclusives": {
    infoTitle: "ma_web_loyalty_early_access_to_exclusives_info_title",
    infoDescription:
      "ma_web_loyalty_early_access_to_exclusives_info_description",
    howToUse: [
      "ma_web_loyalty_early_access_to_exclusives_how_to_use_1",
      "ma_web_loyalty_early_access_to_exclusives_how_to_use_2",
      "ma_web_loyalty_early_access_to_exclusives_how_to_use_3",
    ],
    additionalInfo: {
      title: "ma_web_loyalty_early_access_to_exclusives_additional_info_title",
      description:
        "ma_web_loyalty_early_access_to_exclusives_additional_info_description",
    },
    eventLibraryTitle:
      "ma_web_loyalty_early_access_to_exclusives_event_library_title",
    eventLibraryCta:
      "ma_web_loyalty_early_access_to_exclusives_event_library_cta",
    eventLibraryCtaAlternative:
      "ma_web_loyalty_early_access_to_exclusives_event_library_cta_alternative",
  },
  "members-only-sale": {
    infoTitle: "ma_web_loyalty_members_only_sale_info_title",
    infoDescription: "ma_web_loyalty_members_only_sale_info_description",
    howToUse: [
      "ma_web_loyalty_members_only_sale_how_to_use_1",
      "ma_web_loyalty_members_only_sale_how_to_use_2",
      "ma_web_loyalty_members_only_sale_how_to_use_3",
    ],
    additionalInfo: {
      title: "ma_web_loyalty_members_only_sale_additional_info_title",
      description:
        "ma_web_loyalty_members_only_sale_additional_info_description",
    },
    eventLibraryTitle: "ma_web_loyalty_members_only_sale_event_library_title",
    eventLibraryCta: "ma_web_loyalty_members_only_sale_event_library_cta",
    eventLibraryCtaAlternative:
      "ma_web_loyalty_members_only_sale_event_library_cta_alternative",
  },
  "early-access-to-sale": {
    infoTitle: "ma_web_loyalty_early_access_to_sale_info_title",
    infoDescription: "ma_web_loyalty_early_access_to_sale_info_description",
    howToUse: [
      "ma_web_loyalty_early_access_to_sale_how_to_use_1",
      "ma_web_loyalty_early_access_to_sale_how_to_use_2",
      "ma_web_loyalty_early_access_to_sale_how_to_use_3",
    ],
    additionalInfo: {
      title: "ma_web_loyalty_early_access_to_sale_additional_info_title",
      description:
        "ma_web_loyalty_early_access_to_sale_additional_info_description",
    },
    eventLibraryTitle:
      "ma_web_loyalty_early_access_to_sale_event_library_title",
    eventLibraryCta: "ma_web_loyalty_early_access_to_sale_event_library_cta",
    eventLibraryCtaAlternative:
      "ma_web_loyalty_early_access_to_sale_event_library_cta_alternative",
  },
  "in-real-life-events": {
    infoTitle: "ma_web_loyalty_in_real_life_events_info_title",
    infoDescription: "ma_web_loyalty_in_real_life_events_info_description",
    howToUse: [
      "ma_web_loyalty_in_real_life_events_how_to_use_1",
      "ma_web_loyalty_in_real_life_events_how_to_use_2",
      "ma_web_loyalty_in_real_life_events_how_to_use_3",
    ],
    eventLibraryTitle: "ma_web_loyalty_in_real_life_events_event_library_title",
    eventLibraryCta: "ma_web_loyalty_in_real_life_events_event_library_cta",
    eventLibraryCtaAlternative:
      "ma_web_loyalty_in_real_life_events_event_library_cta_alternative",
  },
  "priority-back-in-stock": {
    infoTitle: "ma_web_loyalty_priority_back_in_stock_info_title",
    infoDescription: "ma_web_loyalty_priority_back_in_stock_info_description",
    howToUse: [
      "ma_web_loyalty_priority_back_in_stock_how_to_use_1",
      "ma_web_loyalty_priority_back_in_stock_how_to_use_2",
      "ma_web_loyalty_priority_back_in_stock_how_to_use_3",
    ],
    infoBanner: "ma_web_loyalty_priority_back_in_stock_info_banner_text",
    ctaText: "ma_web_loyalty_priority_back_in_stock_cta_text",
    ctaUrl: isWebView()
      ? "asos://savedlist?sort=stock-level"
      : "https://www.asos.com/saved-lists/?nlid=nav+header&sort=stock",
  },
};
