import React, { useEffect, useRef, useState, FocusEvent } from "react";

import classnames from "classnames";

import FormField from "../form-field";

import styles from "./index.css";

export type InputFieldProps = {
  button?: React.ReactNode;
  hasMargin?: boolean;
  hasSmallMargin?: boolean;
  hidden?: boolean;
  iconSrc?: string;
  input: {
    name: string;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  };
  optionalRef?: () => void;
  label: string;
  meta: {
    form?: string;
    error?: {
      id: string;
    };
    touched?: boolean;
  };
  required?: boolean;
  showLabel?: boolean;
  showRequired?: boolean;
  type?: "email" | "number" | "password" | "search" | "tel" | "text" | "url";
  autoFocus?: boolean;
  describeTextId?: string;
};

const InputField: React.FC<InputFieldProps> = ({
  button = null,
  hasMargin = true,
  hasSmallMargin = false,
  hidden = false,
  iconSrc = null,
  input,
  label,
  meta,
  required = false,
  showLabel = true,
  type = "text",
  autoFocus = false,
  describeTextId = "",
  optionalRef = null,
  showRequired = false,
  ...props
}) => {
  const [focused, setFocused] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      setFocused(true);
    }
  }, [autoFocus]);

  useEffect(() => {
    if (focused && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focused]);

  const ariaDescribedBy = {
    error: `error-${input.name}`,
    label: `label-${input.name}`,
    additionalDescribeByText: describeTextId,
  };

  const getDescribedBy = (ariaDescribedBy) => {
    let describeBy = `${ariaDescribedBy.label} ${ariaDescribedBy.error}`;
    if (ariaDescribedBy.additionalDescribeByText) {
      describeBy += " " + ariaDescribedBy.additionalDescribeByText;
    }

    return describeBy;
  };

  return (
    <FormField
      {...meta}
      ariaDescribedBy={ariaDescribedBy}
      data-auto-id={`${meta.form}-${input.name}`}
      hidden={hidden}
      label={label}
      hasMargin={hasMargin}
      hasSmallMargin={hasSmallMargin}
      showLabel={showLabel}
      showRequired={showRequired}
    >
      <span className={styles.inputWrapper}>
        <input
          ref={(c) => {
            autoFocus ? inputRef : null;
            if (optionalRef && c) {
              optionalRef.call(null, c);
            }
          }}
          {...props}
          {...input}
          id={ariaDescribedBy.label}
          hidden={hidden}
          aria-invalid={meta.error && meta.touched}
          required={required}
          aria-describedby={getDescribedBy(ariaDescribedBy)}
          className={classnames({
            [styles.input]: true,
            [styles.hasButton]: button,
            [styles.hasIcon]: iconSrc,
            [styles.password]: type === "password",
            [styles.search]: type === "search",
          })}
          onBlur={(event) => {
            if (autoFocus) {
              setFocused(false);
            }
            input.onBlur(event);
          }}
          type={type}
          data-cs-mask
        />
        {button && <span className={styles.buttonWrapper}>{button}</span>}
        {iconSrc && (
          <img aria-hidden className={styles.icon} src={iconSrc} alt="" />
        )}
      </span>
    </FormField>
  );
};

export default InputField;
