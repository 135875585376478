import { createAction } from "redux-actions";
import { getFormInitialValues } from "redux-form";

import Cookie from "js-cookie";

import { getAuthHomeCountry } from "@state/identity/selectors";
import { getHomeCountry } from "@state/user/selectors";

import { getSiteDomain } from "../../utils/url";
import { requestNotification } from "../application/actions";
import { appLoaded, getLanguage, getSiteRoot } from "../application/selectors";
import {
  getCommunicationPreferences as fetchCommunicationPreferences,
  saveCommunicationPreferences as saveCommunicationPreferencesApi,
} from "./api";
import { getServiceId } from "./selectors";
import {
  API_ERROR,
  CONFIRM_SAVED_COMMUNICATION_PREFERENCES,
  RECEIVE_PREFERENCES,
  REQUEST_PREFERENCES,
  TRACK_SAVED_COMMUNICATION_PREFERENCES,
} from "./types";

const FORM_NAME = "commPrefs";

export const isFatalError = (err) =>
  err.statusCode !== 400 ||
  err.body.findIndex(({ errorCode }) => errorCode === "badRequest") > -1;

export const confirmSavedCommunicationPreferences = createAction(
  CONFIRM_SAVED_COMMUNICATION_PREFERENCES
);
export const trackSavedCommunicationPreferences = createAction(
  TRACK_SAVED_COMMUNICATION_PREFERENCES
);

export const apiError = createAction(API_ERROR);

export const receivePreferences = createAction(RECEIVE_PREFERENCES);
export const requestPreferences = createAction(REQUEST_PREFERENCES);

export const getCommunicationPreferences =
  (getCommunicationPreferencesFn = fetchCommunicationPreferences) =>
  async (dispatch, getState, identity) => {
    const state = getState();

    if (!appLoaded(state)) {
      return;
    }

    dispatch(requestPreferences());
    try {
      const result = await getCommunicationPreferencesFn(
        getHomeCountry(state) || getAuthHomeCountry(state),
        getLanguage(state),
        identity
      );

      dispatch(receivePreferences(result));
    } catch (err) {
      dispatch(receivePreferences(err));
    }
  };

const getSelectedCustomerChannels = (preference) =>
  preference.channels.filter((channel) => preference[channel]);

const getUpdatedCustomerChannels = (preSave, postSave) =>
  postSave.channels.filter(
    (channel) => postSave[channel] !== preSave[channel] && postSave[channel]
  );

const formatFormValues = (values) =>
  values.preferenceIds &&
  values.preferenceIds.map((id) => ({
    preferenceId: values[id].preferenceId,
    customerChannels: getSelectedCustomerChannels(values[id]),
  }));

const formatFormValuesForAnalytics = (values) =>
  values.preferenceIds &&
  values.preferenceIds.map((key) => ({
    [key]: getSelectedCustomerChannels(values[key]),
  }));

const formatUpdatedFormValuesForAnalytics = (initialValues, postValues) =>
  postValues.preferenceIds &&
  postValues.preferenceIds.map((key) => ({
    [key]: getUpdatedCustomerChannels(initialValues[key], postValues[key]),
  }));

export const saveCommunicationPreferences =
  (
    formValues,
    saveCommunicationPreferencesfn = saveCommunicationPreferencesApi,
    getFormInitialValuesFn = getFormInitialValues,
    setCookieFn = Cookie.set
  ) =>
  async (dispatch, getState, identity) => {
    dispatch(requestPreferences());

    const initialValues = getFormInitialValuesFn(FORM_NAME)(getState());
    const formattedValues = formatFormValues(formValues);

    try {
      const serviceIds = getServiceId(getState());
      const userPreferences = serviceIds.reduce(
        (acc, { serviceId, preferences }) => {
          acc[serviceId] = preferences.map((preference) =>
            formattedValues.find((item) => item.preferenceId === preference)
          );
          return acc;
        },
        {}
      );
      await saveCommunicationPreferencesfn(userPreferences, identity);

      const domain = `.${getSiteDomain(getSiteRoot(getState()))}`;
      setCookieFn("identitySdkResetRequired", "true", { domain, path: "/" });

      dispatch(confirmSavedCommunicationPreferences(formattedValues));
      dispatch(
        trackSavedCommunicationPreferences({
          preferencesPreSave: formatFormValuesForAnalytics(initialValues),
          preferencesPostSave: formatUpdatedFormValuesForAnalytics(
            initialValues,
            formValues
          ),
        })
      );
      dispatch(
        requestNotification(
          "success",
          "ma_web_communicationpreferences_preferencessaved"
        )
      );
    } catch (err) {
      dispatch(apiError());

      throw err;
    }
  };
