import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import classNames from "classnames";
import PropTypes from "prop-types";

import { Card } from "@asosteam/asos-web-component-library-cards";
import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";
import { ProgressIndicator } from "@asosteam/asos-web-component-library-progress-indicator";

import { Button } from "@components/button";
import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useThemeContext } from "@context/ThemeContext";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { handleTrackLoyaltyButtonClick } from "@state/loyalty/actions";
import {
  getBenefitDetailsByType,
  getUnlockedBenefitIds,
} from "@state/loyalty/selectors";
import { getGender } from "@state/user/selectors";

import { redirectToUrl } from "@utils/url";

import baseStyles from "../../../index.css";
import { TierBadge } from "../tier-badge";
import { isWebView, setBodyDark } from "../utilities";
import { AppBanner } from "./app-banner";
import { benefitConfig } from "./benefit-config";
import EventLibrary from "./event-library";
import { Hero } from "./hero";

import styles from "./index.css";

type BenefitDetailsProps = {
  lockedBenefit?: string;
  birthdayTileNoDOB?: boolean;
  benefitTier?: number;
};

const BenefitDetails: React.FC<BenefitDetailsProps> = ({
  lockedBenefit,
  birthdayTileNoDOB,
  benefitTier,
}) => {
  const { isDarkMode } = useThemeContext();
  setBodyDark(isDarkMode);

  const { benefitName } = useParams();
  const dispatch = useDispatch();
  const benefitToDisplay = (lockedBenefit || benefitName) as string;
  const gender = useSelector((state: any) => getGender(state));
  const kebabCaseToCamelCase = (kebabCase: string) =>
    kebabCase
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");
  const benefitDetails = useSelector((state: any) =>
    getBenefitDetailsByType(
      state,
      kebabCaseToCamelCase(benefitToDisplay),
      lockedBenefit && !birthdayTileNoDOB
    )
  );
  const unlockedBenefitIds = useSelector((state: any) =>
    getUnlockedBenefitIds(state)
  );

  const formatTranslation = useFormatTranslation();

  const getTopLevelCTAHref = (benefitInfo) => {
    if (benefitInfo?.ctaUrl) {
      return benefitInfo.ctaUrl;
    }
    return benefitInfo.ctaUrlFn && benefitInfo.ctaUrlFn(gender);
  };

  const appExclusive =
    !isWebView() && benefitDetails?.tags.includes("AppExclusive");

  const renderBenefitDetails = () => {
    const detailsWrapperClasses = classNames(styles.detailsWrapper, {
      [styles.lockedBenefit]: lockedBenefit,
      [baseStyles.darkModeAsosLayer1]: isDarkMode,
    });

    const renderBenefitDescription = () => (
      <div className={styles.benefitDesc}>
        {lockedBenefit && (
          <TierBadge
            tierId={benefitTier}
            size="medium"
            isDarkMode={isDarkMode}
          />
        )}
        <h2>{formatTranslation(benefitConfig[benefitToDisplay].infoTitle)}</h2>
        <p>
          {benefitToDisplay === "birthday-discount"
            ? formatTranslation(
                benefitConfig[benefitToDisplay].infoDescription,
                {
                  discount: benefitDetails?.metadata?.discountPercentage,
                }
              )
            : formatTranslation(
                benefitConfig[benefitToDisplay].infoDescription
              )}
        </p>
        {birthdayTileNoDOB && renderBirthdayTileNoDOB()}
      </div>
    );

    const renderBirthdayTileNoDOB = () => (
      <>
        <Button
          size="large"
          buttonType="primary-asos-world"
          className={styles.ctaButton}
          fillContainer={false}
          onClick={() => {
            dispatch(
              handleTrackLoyaltyButtonClick(
                formatTranslation(
                  "ma_web_loyalty_birthday_discount_add_birthday"
                ).toLowerCase()
              )
            );
            window.location.href = isWebView()
              ? "asos://my-account/details"
              : "my-details";
          }}
        >
          {formatTranslation("ma_web_loyalty_birthday_discount_add_birthday")}
        </Button>
        <div className={styles.infoBanner}>
          <NotificationBar type="alert">
            {formatTranslation(
              "ma_web_loyalty_birthday_discount_add_birthday_infobanner"
            )}
          </NotificationBar>
        </div>
      </>
    );

    const renderUnlockedBenefit = () => (
      <>
        {benefitConfig[benefitToDisplay].infoBanner && (
          <div className={styles.infoBanner}>
            <NotificationBar type="alert">
              {formatTranslation(benefitConfig[benefitToDisplay].infoBanner)}
            </NotificationBar>
          </div>
        )}
        {benefitConfig[benefitToDisplay].ctaText &&
          (!appExclusive || isWebView()) && (
            <Button
              size="large"
              buttonType="primary-asos-world"
              className={styles.ctaButton}
              fillContainer={false}
              elementOverride={"a"}
              onClick={() => {
                dispatch(
                  handleTrackLoyaltyButtonClick(
                    formatTranslation(
                      benefitConfig[benefitToDisplay].ctaText
                    ).toLowerCase()
                  )
                );
              }}
              href={getTopLevelCTAHref(benefitConfig[benefitToDisplay])}
              target={isWebView() ? "_blank" : "_self"}
            >
              {formatTranslation(benefitConfig[benefitToDisplay].ctaText)}
            </Button>
          )}
        {appExclusive && !isWebView() && (
          <div className={styles.appBannerWrapper}>
            <AppBanner benefitType={benefitDetails?.type} />
          </div>
        )}
        {benefitDetails?.eventSchedule && (
          <EventLibrary
            eventSchedule={benefitDetails.eventSchedule}
            title={benefitConfig[benefitToDisplay].eventLibraryTitle}
            ctaText={
              !appExclusive || (appExclusive && isWebView())
                ? benefitConfig[benefitToDisplay].eventLibraryCta
                : benefitConfig[benefitToDisplay].eventLibraryCtaAlternative
            }
            alternativeCtaText={
              benefitConfig[benefitToDisplay].eventLibraryCtaAlternative
            }
            lockedBenefit={!!lockedBenefit}
          />
        )}
        {renderHowToUse()}
      </>
    );

    const renderHowToUse = () => (
      <div
        className={classNames(styles.howToUse, {
          [baseStyles.darkModeAsosLayer2]: isDarkMode,
        })}
      >
        <h3>{formatTranslation("ma_web_loyalty_how_to_use_this_benefit")}</h3>
        <ul>
          {benefitConfig[benefitToDisplay].howToUse.map((step, index) => (
            <li key={index}>
              {index + 1}. {formatTranslation(step)}
            </li>
          ))}
        </ul>
        {benefitConfig[benefitToDisplay].additionalInfo && (
          <p>
            <span>
              {formatTranslation(
                benefitConfig[benefitToDisplay].additionalInfo.title
              )}
            </span>{" "}
            {formatTranslation(
              benefitConfig[benefitToDisplay].additionalInfo.description
            )}
          </p>
        )}
      </div>
    );

    const renderLockedBenefit = () => (
      <>
        {benefitDetails?.eventSchedule && (
          <EventLibrary
            eventSchedule={benefitDetails.eventSchedule}
            title={benefitConfig[benefitToDisplay].eventLibraryTitle}
            ctaText={
              !appExclusive || (appExclusive && isWebView())
                ? benefitConfig[benefitToDisplay].eventLibraryCta
                : benefitConfig[benefitToDisplay].eventLibraryCtaAlternative
            }
            alternativeCtaText={
              benefitConfig[benefitToDisplay].eventLibraryCtaAlternative
            }
            lockedBenefit={!!lockedBenefit}
          />
        )}
      </>
    );

    return (
      <>
        <Hero
          title={benefitDetails?.name}
          imageUrl={benefitDetails?.imageUrl}
          className={classNames(styles.benefitDetailsHero, {
            [styles.birthdayTileNoDOB]: birthdayTileNoDOB,
          })}
          appExclusive={
            !lockedBenefit && benefitDetails?.tags.includes("AppExclusive")
          }
          lockedBenefit={!!lockedBenefit && !birthdayTileNoDOB}
          benefitTier={lockedBenefit ? benefitTier : 0}
        />
        <Card>
          <div className={detailsWrapperClasses}>
            {renderBenefitDescription()}
            {!lockedBenefit ? renderUnlockedBenefit() : renderLockedBenefit()}
          </div>
        </Card>
      </>
    );
  };

  if (!unlockedBenefitIds.includes(benefitDetails.id) && !lockedBenefit) {
    localStorage.setItem("lockedBenefitToOpen", benefitToDisplay);
    const redirectUrl = new URL(window.location.href);
    redirectUrl.pathname = "/my-account/asos-world";
    redirectToUrl(redirectUrl.href);
    return (
      <div className={styles.fullScreenProgress}>
        <ProgressIndicator />
      </div>
    );
  }

  return (
    <>
      {lockedBenefit ? (
        renderBenefitDetails()
      ) : (
        <Page
          loaded={true}
          pageHeader={
            <PageHeader
              title={benefitDetails?.name}
              icon="loyaltyPurple"
              hasBottomPadding
              previousPageLink="/asos-world"
              titleCenterAlign
            />
          }
          pageId="benefit-details"
          pageTitleKey="ma_web_loyalty_hub_title"
          showErrorSection={false}
          showScrollToTopButton
        >
          {renderBenefitDetails()}
        </Page>
      )}
    </>
  );
};

BenefitDetails.propTypes = {
  lockedBenefit: PropTypes.string,
  benefitTier: PropTypes.number,
};

BenefitDetails.defaultProps = {
  lockedBenefit: "",
};

export default BenefitDetails;
