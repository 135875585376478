import React from "react";

import classnames from "classnames";

import styles from "./index.css";

type Options = {
  text: string;
  value: any;
};

type SelectProps = {
  className?: string;
  options: Options[];
  placeholder?: string;
  shortWidth?: boolean;
  [x: string]: any;
};

const Select: React.FC<SelectProps> = ({
  className = undefined,
  options,
  placeholder = undefined,
  shortWidth = false,
  ...props
}) => (
  <div
    className={classnames(styles.select, { [styles.shortWidth]: shortWidth })}
  >
    <select {...props} className={classnames(className)} data-cs-mask>
      {placeholder && (
        <option disabled value="">
          {placeholder}
        </option>
      )}
      {options.map((opt) => (
        <option key={opt.value} value={opt.value} data-cs-mask>
          {opt.text}
        </option>
      ))}
    </select>
    <span className={styles.chevronDown} />
  </div>
);

export default Select;
