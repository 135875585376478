import { getPageType } from "./getPageNames";

export const trackPageView = (pathname) => {
  window._uxa = window._uxa || [];

  const pageType = getPageType(pathname);

  window._uxa.push([
    "trackPageview",
    `${window.location.pathname}${window.location.hash.replace(
      "#",
      "?__"
    )}?cs-my-account-page=${pageType}`,
  ]);
};
