import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";

import classNames from "classnames";
import PropTypes from "prop-types";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { LONDON_1 } from "@asosteam/asos-web-component-library-typography";

import { useThemeContext } from "@context/ThemeContext";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import closeIcon from "./close.svg";

import styles from "./index.css";

const Modal = ({
  onRequestClose,
  padContent = true,
  title,
  children,
  hasBottomPadding = true,
  headerClassName = "",
  modalClassName = "",
  overlayClassName = "",
  cardWrapper = true,
  type = "center",
  ...rest
}) => {
  const { isDarkMode } = useThemeContext();
  const formatTranslation = useFormatTranslation();
  const Component = cardWrapper ? Card : "div";

  useEffect(() => {
    if (process.env.NODE_ENV !== "test") {
      ReactModal.setAppElement("#app");
    }
  }, []);

  const classes = classNames(styles.overlay, {
    [styles.default]: type === "center",
    [styles.sideRight]: type === "right",
    [styles.bottomSheet]: type === "bottom",
    [styles.dark]: isDarkMode,
    [overlayClassName]: !!overlayClassName,
  });

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd < -75) {
      onRequestClose();
    }
  };

  return (
    <ReactModal
      aria={{ modal: true }}
      className={classNames({
        [styles.modal]: !modalClassName,
        [modalClassName]: !!modalClassName,
      })}
      closeTimeoutMS={type === "bottom" ? 300 : 150}
      onRequestClose={onRequestClose}
      overlayClassName={classes}
      role="dialog"
      {...rest}
    >
      <Component>
        <CardSection
          hasBottomPadding={false}
          hasRightPadding={type !== "bottom"}
          hasLeftPadding={type !== "bottom"}
          hasTopPadding={type !== "bottom"}
          className={headerClassName}
        >
          <div
            className={styles.wrapper}
            onTouchStart={(e) => type === "bottom" && handleTouchStart(e)}
            onTouchMove={(e) => type === "bottom" && handleTouchMove(e)}
            onTouchEnd={() => type === "bottom" && handleTouchEnd()}
          >
            {type !== "bottom" && (
              <Heading
                sizing={LONDON_1}
                domElement="h2"
                className={styles.title}
                isUppercase={true}
              >
                {title}
              </Heading>
            )}
            {onRequestClose && (
              <button
                aria-label={formatTranslation("ma_web_modal_close")}
                className={classNames(styles.close, {
                  [styles.closeDark]: isDarkMode,
                })}
                onClick={onRequestClose}
                data-auto-id="modal-close"
              >
                {type !== "bottom" ? (
                  <img alt="" src={closeIcon} />
                ) : (
                  <span
                    className={classNames(styles.closeHandle, {
                      [styles.closeHandleDark]: isDarkMode,
                    })}
                  />
                )}
              </button>
            )}
          </div>
        </CardSection>
        <CardSection
          data-auto-id="modal-content"
          hasLeftPadding={padContent}
          hasRightPadding={padContent}
          hasBottomPadding={hasBottomPadding}
          hasTopPadding={false}
        >
          {children}
        </CardSection>
      </Component>
    </ReactModal>
  );
};

Modal.propTypes = {
  ...ReactModal.propTypes,
  cardWrapper: PropTypes.bool,
  hasBottomPadding: PropTypes.bool,
  headerClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  onRequestClose: PropTypes.func,
  overlayClassName: PropTypes.string,
  padContent: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Modal.defaultProps = {
  padContent: true,
  type: "center",
};

export default Modal;
