import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classnames from "classnames";

import {
  CardList,
  Card,
  CardSection,
} from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";
import { LONDON_2 } from "@asosteam/asos-web-component-library-typography";

import Footer from "@components/footer";
import Page from "@components/page";
import PageHeader from "@components/page-header";
import Icon from "@components/picture-icon";
import { Translation } from "@components/translation";

import { useThemeContext } from "@context/ThemeContext";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import {
  getCurrency,
  getLanguage,
  getIfMobilePlatform,
} from "@state/application/selectors";
import {
  getIsFirstView,
  getLoyaltyStatus,
  getNumericalCurrentTierId,
} from "@state/loyalty/selectors";
import { hasValidDateOfBirth } from "@state/user/selectors";

import { formatPrice } from "@utils/format-price";
import { isMockedEnvironment } from "@utils/mock-environment-checker";

import baseStyles from "../../../index.css";
import { BenefitTile } from "../benefit-tile";
import { TierBadge } from "../tier-badge";
import { TierCard } from "../tier-card";
import { getHighestPriorityTag, isWebView, setBodyDark } from "../utilities";

import styles from "./index.css";

type Tier = {
  id: string;
  name: string;
  pointsToUnlock: number;
  remainingPointsToUnlock: number;
  cumulativeBenefitIds: Array<string>;
  tierBenefitIds: Array<string>;
  benefits: Array<Benefit>;
};

type Benefit = {
  id: number;
  imageUrl: string;
  name: string;
};

export const LoyaltyHub: React.FC = () => {
  const { isDarkMode } = useThemeContext();
  setBodyDark(isDarkMode);
  const formatTranslation = useFormatTranslation();
  const loyaltyStatus = useSelector((state: any) => getLoyaltyStatus(state));
  const currentTierId = useSelector((state: any) =>
    getNumericalCurrentTierId(state)
  );
  const isFirstView = useSelector((state) => getIsFirstView(state));
  const language = useSelector((state: any) => getLanguage(state));
  const currency = useSelector((state: any) => getCurrency(state));
  const hasDateOfBirth = useSelector((state: any) =>
    hasValidDateOfBirth(state)
  );
  const isMobile = useSelector((state) => getIfMobilePlatform(state));

  const [showWelcomeNotification, setShowWelcomeNotification] = useState(
    isFirstView && !isWebView()
  );

  useEffect(() => {
    if (isFirstView && !isWebView()) {
      setShowWelcomeNotification(true);
      localStorage.removeItem("loyaltyFirstView");
    }
  }, [isFirstView]);

  const formatSpendRequired = (
    language: string,
    currency: string,
    value: number
  ) => {
    const price = formatPrice(language, currency, value);
    const removeDecimals = ["GBP", "USD", "EUR"];
    return removeDecimals.includes(currency) ? price.split(".")[0] : price;
  };

  const liveEventsCount = (events) => {
    const liveEvents = [];
    events.forEach((event) => {
      const now = new Date();
      if (new Date(event.startDate) < now && new Date(event.endDate) > now) {
        liveEvents.push(event);
      }
    });
    return liveEvents?.length;
  };

  const getSupportedTags = (benefit, tags) => {
    const eventCount =
      benefit.eventSchedule?.events?.length > 0 &&
      liveEventsCount(benefit.eventSchedule.events);
    return {
      AppExclusive: {
        key: "ma_web_loyalty_hub_app_exclusive",
        priority: 1,
        shouldDisplay: !isWebView(),
      },
      Events: {
        key:
          eventCount > 1
            ? "ma_web_loyalty_hub_events_live"
            : "ma_web_loyalty_hub_event_live",
        priority: 2,
        shouldDisplay: tags.includes("Events") && eventCount > 0,
        count: eventCount,
      },
      Sale: {
        key: "ma_web_loyalty_hub_sale_live",
        priority: 3,
        shouldDisplay: tags.includes("Sale") && eventCount > 0,
      },
    };
  };

  const renderBenefitTiles = (
    benefitIds: Array<string>,
    iconTier: string,
    tierId: string
  ) =>
    benefitIds.map((id: string) => {
      const benefitToMap = loyaltyStatus.benefits.find(
        (benefit) => benefit.id === id
      );
      const highestPriorityTag =
        benefitToMap.type === "BirthdayDiscount" && !hasDateOfBirth
          ? { key: "ma_web_loyalty_hub_dob_missing" }
          : getHighestPriorityTag(
              benefitToMap.tags,
              getSupportedTags(benefitToMap, benefitToMap.tags)
            );
      return (
        <BenefitTile
          key={id}
          locked={
            !loyaltyStatus.tiers
              .find((tier) => tier.id === loyaltyStatus.currentTierId)
              .cumulativeBenefitIds.includes(id)
          }
          title={benefitToMap.name}
          tier={iconTier}
          tierId={parseInt(tierId.slice(-1))}
          imageUrl={benefitToMap.imageUrl}
          benefitType={benefitToMap.type}
          tag={highestPriorityTag && highestPriorityTag.key}
          tagCount={
            highestPriorityTag && highestPriorityTag.count
              ? parseInt(highestPriorityTag.count)
              : null
          }
          birthdayTileNoDOB={
            benefitToMap.type === "BirthdayDiscount" && !hasDateOfBirth
          }
        />
      );
    });

  return (
    <Page
      loaded={true}
      pageHeader={
        !isWebView() && (
          <PageHeader
            title="ma_web_loyalty_hub_title"
            icon="account"
            hasBottomPadding
            buttonText="ma_web_loyalty_hub_title_text"
            buttonOnRight={false}
            isLoyalty={true}
          />
        )
      }
      pageId="loyalty-hub"
      pageTitleKey="ma_web_loyalty_hub_title"
      showErrorSection={false}
      showScrollToTopButton
    >
      <div
        className={classnames(styles.cardWrapper, {
          [baseStyles.darkWrapper]: isDarkMode,
          [styles.dark]: isDarkMode,
          [styles.firstView]: isFirstView,
          [styles.isWebView]: isWebView(),
        })}
      >
        {loyaltyStatus && (
          <CardList>
            {!isWebView() && (
              <div className={styles.welcomeMessage}>
                <Translation id="ma_web_loyalty_hub_welcome_message" />
              </div>
            )}
            <Card>
              <CardSection
                isListCard={false}
                className={classnames(styles.tierCardContainer, styles.fixed)}
              >
                <TierCard tierId={currentTierId} />
                <div className={styles.progressDetails}>
                  {currentTierId !== 4 && (
                    <div>
                      <p>
                        <Translation id="ma_web_loyalty_spend_to_reach_next_tier" />
                      </p>
                      <span>
                        {formatSpendRequired(
                          language,
                          currency,
                          loyaltyStatus.tiers[currentTierId]
                            .remainingPointsToUnlock
                        )}
                      </span>
                    </div>
                  )}
                  <div>
                    <p>
                      <Translation id="ma_web_loyalty_tier_renews_on" />
                    </p>
                    <span>
                      {new Date(loyaltyStatus.renewalDate).toLocaleDateString(
                        "en-GB",
                        { year: "numeric", month: "2-digit", day: "2-digit" }
                      )}
                    </span>
                  </div>
                </div>
              </CardSection>
            </Card>

            {showWelcomeNotification && (
              <div className={styles.welcomeNotification}>
                <NotificationBar
                  type="success"
                  onClose={() => setShowWelcomeNotification(false)}
                >
                  {formatTranslation("ma_web_loyalty_hub_welcome_notification")}
                </NotificationBar>
              </div>
            )}

            <CardSection
              className={classnames(styles.loyaltyDrawer, {
                [styles.nativeTopPosition]: isWebView(),
                [baseStyles.darkModeAsosLayer1]: isDarkMode, // needed for tablet
                [styles.nativeTier4TopPosition]:
                  isMobile && currentTierId === 4,
                [styles.disableScrollSnap]:
                  currentTierId === 4 && window.innerHeight > 800,
              })}
            >
              <div
                className={classnames(styles.headerWrapper, {
                  [styles.headerWrapperNative]: isWebView(),
                  [baseStyles.darkModeAsosLayer1]: isDarkMode,
                })}
              >
                <div
                  className={classnames(styles.loyaltyDrawerHandleContainer, {
                    [baseStyles.darkModeAsosLayer1]: isDarkMode,
                  })}
                >
                  <div className={styles.loyaltyDrawerHandle}></div>
                </div>
                <div className={styles.benefitsHeader}>
                  <Heading sizing={LONDON_2} domElement="h2" isUppercase={true}>
                    <Translation id="ma_web_loyalty_hub_benefits_title" />
                  </Heading>
                  <span>
                    <Translation
                      id="ma_web_loyalty_hub_benefits_unlocked"
                      values={{
                        count: loyaltyStatus.tiers.find(
                          (tier) => tier.id === loyaltyStatus.currentTierId
                        ).cumulativeBenefitIds.length,
                        total:
                          loyaltyStatus.tiers[loyaltyStatus.tiers.length - 1]
                            .cumulativeBenefitIds.length,
                      }}
                    />
                  </span>
                </div>
                <div className={styles.benefitWrapper}>
                  {loyaltyStatus.tiers &&
                    loyaltyStatus.tiers.map((tier: Tier, index: number) => {
                      if (currentTierId - 1 > index) return null;
                      const tierName = tier.name.toLowerCase();
                      const iconTier =
                        tierName === "a-lister" ? "alister" : tierName;

                      const html =
                        tier.id === currentTierId.toString()
                          ? formatTranslation(
                              "ma_web_loyalty_hub_tier_header_benefits_unlocked",
                              {
                                count:
                                  loyaltyStatus.tiers[
                                    loyaltyStatus.currentTierId
                                  ].cumulativeBenefitIds.length,
                              }
                            )
                          : formatTranslation(
                              "ma_web_loyalty_hub_unlock_spend",
                              {
                                unlock: formatSpendRequired(
                                  language,
                                  currency,
                                  tier.remainingPointsToUnlock
                                ),
                              }
                            );
                      return (
                        <div key={tier.name}>
                          <div className={styles.tierHeading}>
                            <div className={styles.tierName}>
                              <TierBadge
                                tierId={parseInt(tier.id.slice(-1))}
                                size="medium"
                                isDarkMode={isDarkMode}
                              />
                            </div>
                            {tier.remainingPointsToUnlock > 0 && (
                              <div
                                className={styles.benefitSpend}
                                dangerouslySetInnerHTML={{
                                  __html: html,
                                }}
                              />
                            )}
                          </div>
                          <div className={styles.benefits}>
                            {tier.id === loyaltyStatus.currentTierId
                              ? renderBenefitTiles(
                                  tier.cumulativeBenefitIds,
                                  iconTier,
                                  tier.id
                                )
                              : renderBenefitTiles(
                                  tier.tierBenefitIds,
                                  iconTier,
                                  tier.id
                                )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {!isWebView() && (
                  <>
                    <a
                      className={classnames(styles.learnMore, {
                        [baseStyles.darkModeAsosLayer1]: isDarkMode,
                      })}
                      href={`https://${
                        isMockedEnvironment()
                          ? "test.asosservices.com"
                          : "asos.com"
                      }/discover/asos-world`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className={styles.linkText}>
                        {formatTranslation(
                          "ma_web_loyalty_hub_links_learn_more"
                        )}
                      </span>
                      <span className={styles.linkIcon}>
                        <Icon id={isDarkMode ? "darkLink" : "link"} />
                      </span>
                    </a>
                    <span className={styles.mobileFooter}>
                      <div className={styles.hideCardWhenScrolling}></div>
                      <Footer />
                    </span>
                  </>
                )}
              </div>
            </CardSection>
          </CardList>
        )}
      </div>
    </Page>
  );
};

export default LoyaltyHub;
