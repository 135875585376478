const getApplication = (state) => state.application;
const getApplicationConfig = (state) => getApplication(state).config;
const getShopConfig = (state) => getApplicationConfig(state).shopConfig;

export const getIdentityConfig = (state) => getShopConfig(state).identity;

export const getIdentityState = (state) => state.identity;

export const getIsLeaving = (state) => getApplication(state).isLeaving;

export const getShop = (state) => getShopConfig(state).shop;

export const getECommerceStore = (state) =>
  getApplication(state).eCommerceStore;

export const getCountry = (state) => getApplication(state).country;

export const getLanguage = (state) => getApplication(state).language;

export const getPlatform = (state) => getApplication(state).platform;

export const getIfMobilePlatform = (state) =>
  getPlatform(state).toLowerCase() === "mobile";

export const getVisitedPages = (state) => getApplication(state).visitedPages;

export const getAuthorityUri = (state) => getIdentityConfig(state).authorityUri;

export const getIdentityClientId = (state) => getIdentityConfig(state).clientId;

export const getMapsSdkUri = (state) => getApplicationConfig(state).mapsSdkUri;

export const getPermittedPaymentOptions = (state) =>
  getApplicationConfig(state).permittedPaymentOptions;

export const getSecureFormSDKLocation = (state) =>
  getApplicationConfig(state).secureCardFormSDK;

export const getAssetsLocation = (state) =>
  getApplicationConfig(state).assetsLocation;

export const getCurrency = (state) => getApplication(state).currency;

export const getAppStartupError = (state) =>
  !!getApplication(state).appStartupError ||
  !!getIdentityState(state).fatalError;

export const appLoaded = (state) => getApplication(state).loaded;

export const getDigiCertSealCode = (state) => {
  return getShopConfig(state).digiCertSealCode;
};

export const getMapsKey = (state) => getApplicationConfig(state).mapsKey;

export const getSiteRoot = (state) => getApplication(state).siteRoot;

export const getIfChromeShouldBeShown = (state) =>
  getApplication(state).showChrome;

export const getMenuClicked = (state) => getApplication(state).menuClicked;
