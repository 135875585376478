import React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";
import PropTypes from "prop-types";

import { Carousel } from "@asosteam/asos-web-component-library-carousel";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { getIfMobilePlatform } from "@state/application/selectors";

import { isWebView } from "../../../utilities";
import Event from "../event";
import styles from "../index.css";

const EventCarousel = ({
  title,
  events,
  tab,
  buttonText,
  isLive,
  shouldShowCta,
  lockedBenefit,
}) => {
  const formatTranslation = useFormatTranslation();
  const isMobile = useSelector((state) => getIfMobilePlatform(state));

  const getPlatformUrl = (event) =>
    event.actions &&
    event.actions.find(
      (device) => device.name.toLowerCase() === (isWebView() ? "apps" : "web")
    ).url;

  return (
    <>
      {events?.length > 1 && (
        <div
          className={classNames(styles.carousel, {
            [styles.lockedView]: lockedBenefit,
          })}
          id="event-library-carousel"
          aria-label={`${tab} ${formatTranslation(title)} carousel`}
        >
          <Carousel
            ariaDescribedBy="event-library-carousel"
            itemsPerRow={isMobile || lockedBenefit ? 1 : 2}
            paginationLabel="Carousel navigation dots"
            paginationLabelNoNext="Carousel controls no next button"
            paginationLabelNoPrevious="Carousel controls no previous button"
            paginationTabLabel={() => {}}
            paginationTabPanelLabel={() => {}}
            carouselMode={isMobile ? "endlessSideScroll" : "pagination"}
          >
            {events.map((event) => (
              <Event
                key={event.name}
                name={event.name}
                startDate={event.startDate}
                endDate={event.endDate}
                buttonText={buttonText}
                buttonUrl={getPlatformUrl(event)}
                imageUrl={event.imageUrl}
                isLive={isLive}
                shouldShowCta={shouldShowCta}
              />
            ))}
          </Carousel>
        </div>
      )}
      {events?.length === 1 && (
        <Event
          key={events[0].name}
          name={events[0].name}
          startDate={events[0].startDate}
          endDate={events[0].endDate}
          buttonText={buttonText}
          buttonUrl={getPlatformUrl(events[0])}
          imageUrl={events[0].imageUrl}
          isLive={isLive}
          shouldShowCta={shouldShowCta}
        />
      )}
    </>
  );
};

EventCarousel.propTypes = {
  title: PropTypes.string,
  events: PropTypes.array.isRequired,
  tab: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isLive: PropTypes.bool.isRequired,
  shouldShowCta: PropTypes.bool.isRequired,
  lockedBenefit: PropTypes.bool,
};

EventCarousel.defaultProps = {
  title: "",
  lockedBenefit: false,
};

export default EventCarousel;
