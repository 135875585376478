import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";
import PropTypes from "prop-types";

import { SmartLink } from "@asosteam/asos-web-component-library-smart-link";

import Icon from "@components/picture-icon";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { getIfChromeShouldBeShown } from "@state/application/selectors";
import { handleTrackLoyaltyButtonClick } from "@state/loyalty/actions";

import LockedModal from "../locked-modal";
import { StatusTag } from "../status-tag";
import { formatHeaderTreatment } from "../utilities";

import styles from "./index.css";

export interface BenefitTileProps {
  locked: boolean;
  title: string;
  tier: string;
  tierId: number;
  imageUrl: string;
  benefitType: string;
  tag: string;
  tagCount: number;
  birthdayTileNoDOB?: boolean;
}

export const BenefitTile = ({
  locked,
  title,
  tier,
  tierId,
  imageUrl,
  benefitType,
  tag,
  tagCount,
  birthdayTileNoDOB,
}: BenefitTileProps) => {
  const tileClasses = classNames(styles.benefitTile, {
    [styles.unlocked]: !locked,
    [styles.tier1]: tierId === 1,
    [styles.tier2]: tierId === 2,
    [styles.tier3]: tierId === 3,
    [styles.tier4]: tierId === 4,
  });

  const benefitTypeToKebabCase = (type) =>
    type
      .match(/[A-Z][a-z]+/g)
      .join("-")
      .toLowerCase();

  const formatTranslation = useFormatTranslation();

  const lockedBenefitToOpen = localStorage.getItem("lockedBenefitToOpen");
  const [isOpen, setIsOpen] = useState(
    benefitTypeToKebabCase(benefitType) === lockedBenefitToOpen
  );
  const { showChrome } = useSelector((state) => ({
    showChrome: getIfChromeShouldBeShown(state),
  }));
  const dispatch = useDispatch();

  return (
    <span
      className={tileClasses}
      style={{
        backgroundImage: `${locked ? "" : `url(${imageUrl})`}`,
        backgroundSize: `${locked ? "" : "cover"}`,
      }}
    >
      {birthdayTileNoDOB && !locked && (
        <button onClick={() => setIsOpen(true)}>
          {tag && <StatusTag tag={tag} displayOnTile />}
          <div className={styles.header}>
            <h2>{formatHeaderTreatment(title)}</h2>
          </div>
        </button>
      )}
      {!locked && !birthdayTileNoDOB && (
        <SmartLink
          to={`/asos-world/${benefitTypeToKebabCase(benefitType)}${
            !showChrome ? "?chromeless=true" : ""
          }`}
          formatUrl={() => {}}
          onInternalClick={() => {
            dispatch(handleTrackLoyaltyButtonClick("benefit tile"));
          }}
        >
          {tag && <StatusTag tag={tag} tagCount={tagCount} displayOnTile />}
          <div className={styles.header}>
            <h2>{formatHeaderTreatment(title)}</h2>
          </div>
        </SmartLink>
      )}
      {locked && (
        <button
          onClick={() => {
            dispatch(
              handleTrackLoyaltyButtonClick("inaccessible benefit tile")
            );
            setIsOpen(true);
          }}
        >
          <Icon id="info" className={styles.infoIcon} />
          <Icon
            className={styles.padlockIcon}
            id={"padlockFilled"}
            alt={`${formatTranslation(
              "ma_web_loyalty_hub_benefit_locked"
            )} ${formatTranslation("ma_web_loyalty_hub_benefit_badge_alt", {
              tier: tier,
            })}`}
          />

          <div className={styles.header}>
            <h2>{formatHeaderTreatment(title)}</h2>
          </div>
        </button>
      )}
      <LockedModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        lockedBenefit={benefitTypeToKebabCase(benefitType)}
        title={title}
        birthdayTileNoDOB={!locked && birthdayTileNoDOB}
        benefitTier={tierId}
      />
    </span>
  );
};

BenefitTile.propTypes = {
  locked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  tierId: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  tag: PropTypes.string,
  birthdayTileNoDOB: PropTypes.bool,
};

BenefitTile.defaultProps = {
  locked: false,
  tag: "",
  birthdayTileNoDOB: false,
};
