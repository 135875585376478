import React from "react";
import { Route, Routes } from "react-router-dom";

import Error from "../error";
import BenefitDetails from "./benefit-details";
import LoyaltyHub from "./loyalty-hub";

const Loyalty = () => (
  <Routes>
    <Route index element={<LoyaltyHub />} />
    <Route path=":benefitName" element={<BenefitDetails />} />
    <Route path="*" element={<Error />} />
  </Routes>
);

export default Loyalty;
