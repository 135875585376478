export const getLoyaltyStatus = (state) => state.loyalty.loyaltyStatus;
export const getIsOptedIn = (state) =>
  state.loyalty.loyaltyStatus?.consentStatus === "OptedIn";
export const getCurrentTierId = (state) =>
  state.loyalty?.loyaltyStatus?.currentTierId ?? null;
export const getNumericalCurrentTierId = (state) => {
  const currentTierId = getCurrentTierId(state);
  if (!currentTierId) {
    return null;
  }
  return state.loyalty?.loyaltyStatus?.tiers.find(
    (tier) => tier.id === currentTierId
  )?.tierNumber;
};
export const hasLoaded = (state) => state.loyalty.loaded;
export const getIsFirstView = (state) => state.loyalty.firstView;
export const getHasOptInError = (state) => state.loyalty.optInError;
export const getBenefitDetailsById = (state, benefitId) =>
  state.loyalty.loyaltyStatus?.benefits?.find(
    (benefit) => benefit.id === benefitId
  );
export const getBenefitDetailsByType = (state, benefitType, locked) => {
  if (benefitType === "BirthdayDiscount") {
    return locked
      ? getLockedBirthdayBenefit(state)
      : getUnlockedBirthdayBenefit(state);
  }
  return state.loyalty.loyaltyStatus?.benefits?.find(
    (benefit) => benefit.type === benefitType
  );
};
export const getUnlockedBirthdayBenefit = (state) => {
  const unlockedBenefits = state.loyalty.loyaltyStatus?.tiers.find(
    (tier) => tier.id === getCurrentTierId(state)
  ).cumulativeBenefitIds;
  return state.loyalty.loyaltyStatus?.benefits?.find(
    (benefit) =>
      unlockedBenefits.includes(benefit.id) &&
      benefit.type === "BirthdayDiscount"
  );
};

export const getLockedBirthdayBenefit = (state) => {
  const unlockedBenefits = state.loyalty.loyaltyStatus?.tiers.find(
    (tier) => tier.id === getCurrentTierId(state)
  ).cumulativeBenefitIds;
  let lockedBenefits = state.loyalty.loyaltyStatus?.tiers.find(
    (tier) =>
      tier.id ===
      state.loyalty.loyaltyStatus?.tiers[
        state.loyalty.loyaltyStatus?.tiers.length - 1
      ].id
  ).cumulativeBenefitIds;
  lockedBenefits = lockedBenefits.filter(
    (benefit) => !unlockedBenefits.includes(benefit)
  );
  return state.loyalty.loyaltyStatus?.benefits?.find(
    (benefit) =>
      lockedBenefits.includes(benefit.id) && benefit.type === "BirthdayDiscount"
  );
};

export const getUnlockedBenefitIds = (state) => {
  return state.loyalty.loyaltyStatus?.tiers.find(
    (tier) => tier.id === getCurrentTierId(state)
  ).cumulativeBenefitIds;
};

export const getTierNameByTierNumber = (state, tierId) => {
  return state.loyalty.loyaltyStatus?.tiers.find(
    (tier) => tier.tierNumber === tierId
  ).name;
};
