import React from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";

import { Button } from "@components/button";
import Icon from "@components/picture-icon";

import { useThemeContext } from "@context/ThemeContext";

import { getLanguage } from "@state/application/selectors";
import { handleTrackLoyaltyButtonClick } from "@state/loyalty/actions";

import styles from "../index.css";

const formatTime = (date: string) => {
  const time = new Date(date).getHours();
  const ampm = time >= 12 ? "pm" : "am";
  return `${time % 12 || 12}${ampm}`;
};

const Event = ({
  name,
  startDate,
  endDate,
  buttonText,
  buttonUrl,
  imageUrl,
  isLive,
  shouldShowCta,
}) => {
  const language = useSelector((state: any) => getLanguage(state));
  const dispatch = useDispatch();
  const { isDarkMode } = useThemeContext();
  const calendarid = isDarkMode ? "calendarDarkMode" : "calendar";
  const clockid = isDarkMode ? "clockDarkMode" : "clock";
  return (
    <div className={styles.eventWrapper}>
      <div
        className={styles.eventImage}
        style={{
          backgroundImage: `url("${imageUrl}")`,
          backgroundSize: "cover",
        }}
      />
      <div className={styles.eventDetails}>
        <h3>{name}</h3>
        <span className={styles.eventStart}>
          <Icon id={calendarid} size="xtiny" className={styles.eventIcon} />
          {new Date(startDate)
            .toLocaleDateString(language, {
              weekday: "short",
              day: "numeric",
              month: "short",
            })
            .replace(",", "")}
        </span>
        <span>
          <Icon id={clockid} size="xtiny" className={styles.eventIcon} />
          {`${formatTime(startDate)} - ${formatTime(endDate)}`}
        </span>
        {buttonText && buttonUrl && shouldShowCta && (
          <Button
            size="large"
            buttonType={isLive ? "primary-asos-world" : "secondary-asos-world"}
            fillContainer
            onClick={() => {
              dispatch(handleTrackLoyaltyButtonClick(buttonText.toLowerCase()));
            }}
            elementOverride={"a"}
            href={buttonUrl}
            target="_blank"
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

Event.propTypes = {
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string,
  isLive: PropTypes.bool,
  shouldShowCta: PropTypes.bool,
};

Event.defaultProps = {
  buttonText: "",
  buttonHandle: "",
  isLive: false,
  shouldShowCta: true,
};

export default Event;
