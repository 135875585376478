import React from "react";

import classnames from "classnames";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";
import { LONDON_1 } from "@asosteam/asos-web-component-library-typography";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { isWebView } from "../../app/loyalty-hub/utilities";
import { Button } from "../button";

import styles from "./index.css";

export interface NoContentInnerProps {
  buttonLink: { url: string; urlParameters?: Record<any, any> } | string;
  buttonText: string;
  children: React.ReactNode;
  image: React.ReactNode;
  buttonType?: "primary" | "secondary";
  title: string;
}

export const NoContentInner = ({
  image,
  title,
  buttonLink,
  buttonText,
  children,
  buttonType = "primary",
}: NoContentInnerProps) => {
  const formatTranslation = useFormatTranslation();
  const to = typeof buttonLink === "string" ? buttonLink : buttonLink.url;
  const urlParams =
    typeof buttonLink === "object" ? buttonLink.urlParameters : undefined;

  return (
    <div className={styles.innerWrapper} data-auto-id="NoContent">
      {image}
      <Heading
        domElement="h3"
        sizing={LONDON_1}
        className={styles.title}
        isUppercase={true}
      >
        {formatTranslation(title)}
      </Heading>
      <Paragraph className={styles.message}>{children}</Paragraph>
      <div
        className={classnames(
          {
            [styles.hideOnLarge]: buttonType === "secondary",
          },
          {
            [styles.hideOnWebView]: isWebView(),
          },
          styles.buttonWrapper
        )}
      >
        <Button buttonType={buttonType} to={to} urlParameters={urlParams}>
          {formatTranslation(buttonText)}
        </Button>
      </div>
    </div>
  );
};

export interface NoContentScreenProps extends NoContentInnerProps {
  buttonType?: "primary" | "secondary";
  showAsCard?: boolean;
}

const NoContentScreen = ({
  showAsCard = true,
  buttonType = "primary",
  ...innerProps
}: NoContentScreenProps) =>
  showAsCard ? (
    <div className={styles.cardWrapper}>
      <Card>
        <CardSection>
          <NoContentInner buttonType={buttonType} {...innerProps} />
        </CardSection>
      </Card>
    </div>
  ) : (
    <div className={styles.outerWrapper}>
      <NoContentInner buttonType={buttonType} {...innerProps} />
    </div>
  );

export default NoContentScreen;
