import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  Card,
  CardSection,
  CardList,
} from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";
import { LONDON_2 } from "@asosteam/asos-web-component-library-typography";

import { Button } from "@components/button";
import Page from "@components/page";
import PageHeader from "@components/page-header";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import {
  navigateToReturn,
  selectDropOffStore,
  selectPlaceForDropOff,
} from "@state/returns/actions";
import {
  hasDropOffStoreAvailable as hasDropOffStoreAvailableFn,
  hasMutlipleLocationOptions as hasMultipleLocationOptionsFn,
  getDropOffSearchAddress,
  getDropOffStoresProviders,
  getDropOffStores,
  getPlacesSearchResults,
} from "@state/returns/selectors";

import LocationsList from "./locations-list";
import StoresList from "./stores-list";

import styles from "./index.css";

const ReturnLocations = () => {
  const { orderReference } = useParams();
  const formatTranslation = useFormatTranslation();

  const {
    hasDropOffStoreAvailable,
    hasMultipleLocationOptions,
    places,
    providers,
    searchAddress,
    storesList,
  } = useSelector((state) => ({
    hasDropOffStoreAvailable: hasDropOffStoreAvailableFn(state),
    hasMultipleLocationOptions: hasMultipleLocationOptionsFn(state),
    places: getPlacesSearchResults(state),
    providers: getDropOffStoresProviders(state),
    searchAddress: getDropOffSearchAddress(state),
    storesList: getDropOffStores(state),
  }));

  const dispatch = useDispatch();

  if (!hasDropOffStoreAvailable && !hasMultipleLocationOptions) {
    dispatch(navigateToReturn(orderReference));
  }

  const title = hasMultipleLocationOptions
    ? formatTranslation("ma_web_orderreturn_returnmethod_searchbyaddress")
    : formatTranslation("ma_web_orderreturn_returnmethod_resultsfor", {
        smart_count: storesList.length,
      });

  return (
    <Page
      loaded={hasDropOffStoreAvailable || hasMultipleLocationOptions}
      pageHeader={
        <PageHeader
          detached
          previousPageLink={`/orders/${orderReference}/return#method`}
          title="ma_web_orderreturn_dropoff_headertitle"
          type="secondary"
        />
      }
      pageId="select-return-location"
      pageTitleKey="ma_web_orderreturn_dropoff_headertitle"
      showErrorSection={false}
    >
      <CardList>
        <Card>
          <CardSection>
            <div className={styles.titleWrapper}>
              <Heading domElement="h3" sizing={LONDON_2} isUppercase={true}>
                {title}
              </Heading>
            </div>
            <Paragraph data-cs-mask>
              {formatTranslation(
                "ma_web_orderreturn_returnmethod_searchedforaddress",
                { address: searchAddress.formattedAddress }
              )}
            </Paragraph>
            <div className={styles.buttonWrapper}>
              <Button
                buttonType="secondary"
                data-auto-id="search-again-btn"
                onClick={() => dispatch(navigateToReturn())}
              >
                {formatTranslation(
                  "ma_web_orderreturn_returnmethod_searchagaincta"
                )}
              </Button>
            </div>
          </CardSection>
          <CardSection hasBottomPadding={false} hasTopPadding={false}>
            <HorizontalRule />
          </CardSection>
          <CardSection hasBottomPadding={false} hasTopPadding={false}>
            {hasMultipleLocationOptions && (
              <LocationsList
                deliveryCountry={searchAddress.countryCode}
                places={places}
                selectPlaceForDropOff={(address, deliveryCountry) =>
                  dispatch(selectPlaceForDropOff(address, deliveryCountry))
                }
              />
            )}
            {hasDropOffStoreAvailable && (
              <StoresList
                countryCode={searchAddress.countryCode}
                providers={providers}
                selectDropOffStore={(store, provider) =>
                  dispatch(selectDropOffStore(store, provider))
                }
                storesList={storesList}
              />
            )}
          </CardSection>
        </Card>
      </CardList>
    </Page>
  );
};

export default ReturnLocations;
