import React, { FC } from "react";
import { useSelector } from "react-redux";

import type { RootState } from "src/client";

import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";

import { generateUrl } from "./generateUrl";

import styles from "./index.css";

type ReturnSignpostProps = {
  formatTranslation: (key: string, dynamic?: Record<string, string>) => string;
};

export const ReturnSignpost: FC<ReturnSignpostProps> = ({
  formatTranslation,
}) => {
  const { countryCode, storeId, siteroot } = useSelector(
    (state: RootState) => ({
      countryCode: state.application.country,
      storeId: state.application.eCommerceStore,
      siteroot: state.application.siteRoot,
    })
  );

  const url = generateUrl(countryCode, storeId, siteroot);

  const signpost = formatTranslation(
    "customer_care_create_return_signposting",
    {
      howdoireturn: url,
    }
  );

  return (
    <Paragraph className={styles.returnSignpost}>
      <span
        dangerouslySetInnerHTML={{
          __html: signpost,
        }}
        className={styles.link}
      />
    </Paragraph>
  );
};
