import { handleActions } from "redux-actions";

import { CLEAR_API_ERRORS } from "../application/types";
import * as types from "./types";

const updateUserDetails = (state, { error, payload }) => {
  if (error) {
    return {
      ...state,
      loaded: true,
      fatalError: false,
    };
  }

  const {
    account,
    dateOfBirth,
    customerId,
    emailAddresses,
    firstName,
    gender,
    lastName,
    subscriptions,
    homeCountry,
  } = payload;

  let mappedSubscriptions;
  if (subscriptions?.length) {
    mappedSubscriptions = [];
    subscriptions.forEach((subscription) => {
      const newSubscription = {
        ...subscription,
        ...{
          endDate: subscription.expiryDate,
        },
      };
      delete newSubscription.expiryDate;
      mappedSubscriptions.push(newSubscription);
    });
  }
  return {
    ...state,
    account,
    apiError: false,
    customerId,
    dateOfBirth,
    emailAddress: (emailAddresses.find((address) => address.default) || {})
      .emailAddress,
    fatalError: false,
    firstName,
    gender,
    lastName,
    homeCountry,
    loaded: true,
    subscriptions: mappedSubscriptions,
    tries: 0,
  };
};

const reducer = handleActions(
  {
    [types.API_ERROR]: (state) => {
      const { maxRetries, tries } = state;
      if (tries >= maxRetries) {
        return {
          ...state,
          apiError: false,
          fatalError: true,
          loaded: false,
          tries: 0,
        };
      }
      return {
        ...state,
        fatalError: false,
        loaded: true,
        tries: tries + 1,
        apiError: true,
      };
    },
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      fatalError: false,
      apiError: false,
      tries: 0,
    }),

    [types.REQUEST_UPDATE_USER_DETAILS]: (state) => ({
      ...state,
      loaded: false,
    }),
    [types.FINISH_UPDATE_USER_DETAILS]: updateUserDetails,
    [types.REQUEST_USER_DETAILS]: (state) => ({
      ...state,
      fatalError: false,
      loaded: false,
    }),
    [types.RECEIVE_USER_DETAILS]: updateUserDetails,
  },
  {
    apiError: false,
    dateOfBirth: "",
    emailAddress: "",
    fatalError: false,
    firstName: "",
    gender: "",
    lastName: "",
    homeCountry: "",
    maxRetries: 2,
    tries: 0,
    loaded: false,
    subscriptions: [],
  }
);

export default reducer;
