import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

const PageHeaderTitle = ({
  hasDescription,
  title,
  type,
  alignCenter = false,
}) => {
  const formatTranslation = useFormatTranslation();
  const headingText = formatTranslation(title);

  return (
    <h2
      data-auto-id="page-title"
      id="page-title"
      className={classnames(styles.title, {
        [styles.primary]: type === "primary",
        [styles.hasDescription]: hasDescription,
        [styles.alignCenter]: alignCenter,
      })}
      tabIndex="-1"
    >
      {headingText}
    </h2>
  );
};

PageHeaderTitle.propTypes = {
  hasDescription: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["primary", "secondary"]).isRequired,
  alignCenter: PropTypes.bool.isRequired,
};

export default PageHeaderTitle;
