import React, { FC } from "react";

import { Heading } from "@asosteam/asos-web-component-library-heading";
import { LONDON_3 } from "@asosteam/asos-web-component-library-typography";

import Distance from "@components/distance";
import Modal from "@components/modal";
import StoreAddress from "@components/store-address";

import StoreOpeningHours from "../../../return-locations/store-opening-hours";

import styles from "./index.css";

type CollectionPointModalProps = {
  formatTranslation: (arg: string) => string;
  store: {
    address: {
      address1: string;
      address2: string | null;
      address3: string | null;
      locality: string;
      postalCode: string;
    };
    distanceInMeters: number;
    distanceInMiles: number;
    name: string;
    tradingPeriods: {
      day: string;
      weekDayIndex: number;
      openingHours: string;
    }[];
  };
  provider: {
    logoUri: string;
    name: string;
  };
  showModal: boolean;
  toggleModal: (arg: boolean) => void;
};

const CollectionPointModal: FC<CollectionPointModalProps> = ({
  formatTranslation,
  store,
  provider,
  showModal,
  toggleModal,
}) => {
  const title = formatTranslation(
    "ma_web_orderreturn_returnmethod_dropoff_selectedheading"
  ).replace(":", "");

  const openingHours = formatTranslation(
    "ma_web_orderreturn_dropoff_storedetails_openinghours"
  );

  return (
    <Modal
      cardWrapper={false}
      contentLabel={title}
      isOpen={showModal}
      padContent={false}
      title={title}
      onRequestClose={() => {
        toggleModal(false);
      }}
      headerClassName={styles.headerClass}
      hasBottomPadding={false}
      modalClassName={styles.modalClass}
      overlayClassName={styles.overlayClass}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <img
              alt={provider.name}
              className={styles.icon}
              role="presentation"
              src={provider.logoUri}
            />
          </div>
          <div>
            <Heading sizing={LONDON_3} domElement="h3" isUppercase={true}>
              {store.name.toUpperCase()}
            </Heading>
            <Distance
              meters={store.distanceInMeters}
              miles={store.distanceInMiles}
            />
            <StoreAddress address={store.address} />

            <div className={styles.openingTimes}>
              <Heading sizing={LONDON_3} domElement="h3" isUppercase={true}>
                {openingHours.toUpperCase()}
              </Heading>
              <StoreOpeningHours tradingPeriods={store.tradingPeriods} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CollectionPointModal;
