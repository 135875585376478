/*
 * Regexp app wide route definitions, primarily meant
 * for analytics. When expanding this list make sure that
 * more specific definitions should come before less
 * specific ones.
 */

export default [
  {
    context: "my account",
    pageTitle: "my account",
    pattern: /^(\/my-account)?\/?$/,
  },
  {
    context: "my orders",
    pageTitle: "my orders",
    pattern: /^(\/my-account)?\/orders\/?$/i,
  },
  {
    channel2: "my orders",
    context: "order details",
    pageTitle: "order details",
    pattern: /^(\/my-account)?\/orders\/[0-9a-z]*\/?$/i,
  },
  {
    channel2: "my orders",
    context: "create return",
    pageTitle: "create return",
    pattern: /^(\/my-account)?\/orders\/[0-9a-z]*\/return?$/i,
  },
  {
    context: "my returns",
    pageTitle: "my returns",
    pattern: /^(\/my-account)?\/returns\/?$/i,
  },
  {
    channel2: "my returns",
    context: "return details",
    pageTitle: "return details",
    pattern: /^(\/my-account)?\/returns\/[0-9a-z]*\/?$/i,
  },
  {
    context: "gift card vouchers",
    pageTitle: "gift card vouchers",
    pattern: /^(\/my-account)?\/gift-cards-and-vouchers\/?$/i,
  },
  {
    channel2: "gift card vouchers",
    context: "add gift card or voucher",
    pageTitle: "add gift card or voucher",
    pattern: /^(\/my-account)?\/gift-cards-and-vouchers\/add?$/i,
  },
  {
    channel2: "gift card vouchers",
    context: "add gift card",
    pageTitle: "add gift card",
    pattern: /^(\/my-account)?\/gift-cards-and-vouchers\/add\/card?$/i,
  },
  {
    channel2: "gift card vouchers",
    context: "add voucher",
    pageTitle: "add voucher",
    pattern: /^(\/my-account)?\/gift-cards-and-vouchers\/add\/voucher?$/i,
  },
  {
    context: "address book",
    pageTitle: "address book",
    pattern: /^(\/my-account)?\/addresses\/?$/i,
  },
  {
    channel2: "address book",
    context: "address book",
    pageTitle: "address details",
    pattern: /^(\/my-account)?\/addresses\/[0-9]*\/?$/i,
  },
  {
    context: "address book",
    pageTitle: "add address",
    pattern: /^(\/my-account)?\/addresses\/add\/?$/i,
  },
  {
    context: "address book",
    pageTitle: "edit address",
    pattern: /^(\/my-account)?\/addresses\/edit\/[0-9]*\/?$/i,
  },
  {
    context: "payment methods",
    pageTitle: "payment methods",
    pattern: /^(\/my-account)?\/payment-methods\/?$/i,
  },
  {
    context: "payment methods",
    pageTitle: "add payment details",
    pattern: /^(\/my-account)?\/payment-methods\/add(\/card)?\/?$/i,
  },
  {
    context: "my details",
    pageTitle: "my details",
    pattern: /^(\/my-account)?\/my-details\/?$/i,
  },
  {
    context: "social accounts",
    pageTitle: "social accounts",
    pattern: /^(\/my-account)?\/social-accounts\/?$/i,
  },
  {
    context: "premier delivery",
    pageTitle: "premier delivery",
    pattern: /^(\/my-account)?\/premier-delivery\/?$/i,
  },
  {
    context: "activating premier",
    pageTitle: "activating premier",
    pattern: /^(\/my-account)?\/activating-premier\/?$/i,
  },
  {
    context: "premier activation error",
    pageTitle: "premier activation error",
    pattern: /^(\/my-account)?\/premier-activation-error\/?$/i,
  },
  {
    context: "premier delivery terms and conditions",
    pageTitle: "premier delivery terms and conditions",
    pattern: /^(\/my-account)?\/premier-delivery\/terms-and-conditions\/?$/i,
  },
  {
    context: "contact preferences",
    pageTitle: "contact preferences",
    pattern: /^(\/my-account)?\/contact-preferences\/?$/i,
  },
  {
    context: "change password",
    pageTitle: "change password",
    pattern: /^(\/my-account)?\/change-password\/?$/i,
  },
  {
    context: "loyalty hub landing page",
    pageTitle: "loyalty hub landing page",
    pattern: /^(\/my-account)?\/asos-world\/?(?![^/])/i,
  },
  {
    context: "benefit details",
    pageTitle: "benefit details",
    pattern: /^(\/my-account)?\/asos-world\/[^/]+\/?$/i,
  },
];
