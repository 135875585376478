import { handleActions } from "redux-actions";

import without from "lodash.without";

import { CLEAR_API_ERRORS } from "../application/types";
import { hasItemSelectedForReturn } from "./selectors";
import * as types from "./types";

const reducer = handleActions(
  {
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      bookReturnErrorCount: 0,
      createReturnFatalError: false,
      dropOffErrorCount: 0,
      fatalReasonsError: false,
      fatalReferenceError: false,
      noItemsError: false,
      placesErrorCount: 0,
    }),
    [types.CLEAR_RETURNS]: (state) => ({
      ...state,
      dropOffSearchAddress: {
        countryCode: "",
        formattedAddress: "",
      },
      hasAttemptedToPlaceReturn: false,
      noItemsError: false,
      openReturnForms: [],
      returningItems: {},
      returnableItems: {},
      returnReference: null,
    }),
    [types.OPEN_RETURN_FORM]: (state, { payload }) => ({
      ...state,
      openReturnForms: [
        ...state.openReturnForms,
        `${payload.variantId}-${payload.orderReference}`,
      ],
      returningItems: {
        ...state.returningItems,
        [`${payload.variantId}-${payload.orderReference}`]: {
          ...state.returningItems[
            `${payload.variantId}-${payload.orderReference}`
          ],
          orderReference: payload.orderReference,
          orderDate: payload.orderDate,
          isReturning: true,
        },
      },
      noItemsError: false,
    }),
    [types.SET_NO_ITEMS_ERROR]: (state) => ({
      ...state,
      noItemsError: true,
    }),
    [types.SET_REVIEWED_RETURN]: (state, { payload: hasBeenReviewed }) => ({
      ...state,
      hasBeenReviewed,
      attemptedReturnNoReviewMultiParcel: false,
    }),
    [types.SET_REVIEWED_RETURN_ERROR]: (state) => ({
      ...state,
      attemptedReturnNoReviewMultiParcel: true,
    }),
    [types.RESET_REVIEWED_RETURN_ERROR]: (state) => ({
      ...state,
      attemptedReturnNoReviewMultiParcel: false,
    }),
    [types.CLOSE_RETURN_FORM]: (state, { payload }) => {
      const openReturnForms = without(
        state.openReturnForms,
        `${payload.variantId}-${payload.orderReference}`
      );
      const hasAttemptedToPlaceReturn =
        openReturnForms.length === 0 ? false : state.hasAttemptedToPlaceReturn;
      return {
        ...state,
        hasAttemptedToPlaceReturn,
        openReturnForms,
        returningItems: {
          ...state.returningItems,
          [`${payload.variantId}-${payload.orderReference}`]: {
            ...state.returningItems[
              `${payload.variantId}-${payload.orderReference}`
            ],
            isReturning: false,
          },
        },
      };
    },
    [types.RECEIVE_RETURN_REASONS]: (state, { error, payload }) =>
      error
        ? {
            ...state,
            fatalReasonsError: true,
            reasonsLoaded: false,
          }
        : {
            ...state,
            returnReasons: payload.map(({ code, text }) => ({
              value: code,
              text,
            })),
            fatalReasonsError: false,
            reasonsLoaded: true,
          },
    [types.REQUEST_RETURN_REASONS]: (state) => ({
      ...state,
      reasonsLoaded: false,
      fatalReasonsError: false,
    }),
    [types.RECEIVE_RETURN_CHARGE_MESSAGES]: (state, { error, payload }) =>
      error
        ? {
            ...state,
            fatalReturnChargeError: true,
            returnChargeMessageLoaded: false,
          }
        : {
            ...state,
            returnChargeMessage: {
              feeMessage: payload.feeMessage,
              policyDescription: payload.policyDescription,
              postPurchaseWarningMessage: payload.postPurchaseWarningMessage,
              postPurchaseChargeInformationMessage:
                payload.postPurchaseChargeInformationMessage,
            },
            fatalReturnChargeError: false,
            returnChargeMessageLoaded: true,
          },
    [types.REQUEST_RETURNABLE_ITEMS]: (state) => {
      return {
        ...state,
        fatalReturnableItemsError: false,
        returnableItemsLoaded: false,
      };
    },
    [types.RECEIVE_RETURNABLE_ITEMS]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          fatalReturnableItemsError: true,
          returnableItemsLoaded: false,
        };
      }

      const returnableItems = {
        itemsFromSelectedOrder: payload.itemsFromSelectedOrder,
        itemsFromOtherOrders: payload.itemsFromOtherOrders,
      };

      return {
        ...state,
        returnableItems,
        fatalReturnableItemsError: false,
        returnableItemsLoaded: true,
        total: payload.itemCount,
      };
    },
    [types.UPDATE_RETURNING_ITEM]: (state, { payload }) => ({
      ...state,
      returningItems: {
        ...state.returningItems,
        [`${payload.variantId}-${payload.orderReference}`]: {
          quantity: 1,
          isReturning: true,
          ...state.returningItems[
            `${payload.variantId}-${payload.orderReference}`
          ],
          ...payload,
        },
      },
    }),
    [types.RECEIVE_RETURN_REFERENCE]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          returnReference: null,
          fatalReferenceError: true,
        };
      }
      return {
        ...state,
        returnReference: payload,
        fatalReferenceError: false,
        referenceLoaded: true,
      };
    },
    [types.REQUEST_RETURN_REFERENCE]: (state) => ({
      ...state,
      returnReference: null,
      referenceLoaded: false,
    }),
    [types.START_CREATE_RETURN]: (state, { payload }) => ({
      ...state,
      bookedReturn: null,
      creatingReturnFor: payload,
      shouldNotPromptAboutLeaving: false,
    }),
    [types.CLEAR_RETURN_ATTEMPTS]: (state) => ({
      ...state,
      createReturnAttempts: {},
      creatingReturnFor: null,
    }),
    [types.CREATE_RETURN_FAILURE]: (state, { payload }) => {
      const { createReturnAttempts, createReturnMaxTries } = state;
      const previousAttempt = createReturnAttempts.orderReference === payload;
      const returnTries = previousAttempt ? createReturnAttempts.tries + 1 : 1;
      return {
        ...state,
        createReturnFatalError: returnTries >= createReturnMaxTries,
        createReturnAttempts: {
          orderReference: payload,
          tries: returnTries,
        },
        creatingReturnFor: null,
      };
    },
    [types.SEARCH_FOR_ADDRESS]: (state, { payload }) => ({
      ...state,
      dropOffSearchAddress: {
        countryCode: payload.countryCode,
        formattedAddress: payload.formattedAddress,
      },
    }),
    [types.SELECT_PLACE]: (state, { payload }) => ({
      ...state,
      places: [payload],
    }),
    [types.SELECT_LOCATION]: (state, { payload }) => ({
      ...state,
      returnLocation: {
        store: payload.location,
        provider: payload.provider,
      },
    }),
    [types.RESET_RETURN_LOCATION]: (state) => ({
      ...state,
      hasAttemptedToPlaceReturn: false,
      returnLocation: null,
    }),
    [types.REQUEST_GOOGLE_PLACES]: (state) => ({
      ...state,
      places: [],
      shouldNotPromptAboutLeaving: false,
      requestingPlaces: true,
    }),
    [types.RECEIVE_GOOGLE_PLACES]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          shouldNotPromptAboutLeaving: false,
          noPlacesFound: false,
          places: [],
          placesErrorCount: state.placesErrorCount + 1,
          requestingPlaces: false,
        };
      }

      return {
        ...state,
        shouldNotPromptAboutLeaving: payload.length !== 0,
        noPlacesFound: payload.length === 0,
        places: payload,
        placesErrorCount: 0,
        requestingPlaces: false,
      };
    },
    [types.CLEAR_GOOGLE_PLACES_DROP_OFF_ERRORS]: (state) => ({
      ...state,
      dropOffErrorCount: 0,
      noPlacesFound: false,
      placesErrorCount: 0,
    }),
    [types.CLEAR_GOOGLE_PLACES_DROP_OFF_RESULTS]: (state) => ({
      ...state,
      dropOffResults: {
        providers: [],
        stores: [],
      },
      places: [],
    }),
    [types.REQUEST_DROP_OFF_LOCATIONS]: (state) => ({
      ...state,
      dropOffResults: {
        providers: [],
        stores: [],
      },
      requestingDropOffPoints: true,
    }),
    [types.RECEIVE_DROP_OFF_LOCATIONS]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          dropOffErrorCount: state.dropOffErrorCount + 1,
          dropOffResults: {
            providers: [],
            stores: [],
          },
          shouldNotPromptAboutLeaving: false,
          requestingDropOffPoints: false,
        };
      }

      return {
        ...state,
        dropOffErrorCount: 0,
        dropOffResults: {
          providers: payload.providers,
          stores: payload.dropoffPoints,
        },
        shouldNotPromptAboutLeaving: true,
        requestingDropOffPoints: false,
      };
    },
    [types.ATTEMPT_RETURN_BOOKING]: (state) => ({
      ...state,
      hasAttemptedToPlaceReturn: hasItemSelectedForReturn({ returns: state }),
    }),
    [types.REQUEST_RETURN_BOOKING]: (state) => ({
      ...state,
      isBookingReturn: true,
    }),
    [types.RECEIVE_RETURN_BOOKING]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          bookReturnErrorCount: state.bookReturnErrorCount + 1,
          isBookingReturn: false,
          shouldNotPromptAboutLeaving: false,
        };
      }
      return {
        ...state,
        bookedReturn: payload,
        bookReturnErrorCount: 0,
        isBookingReturn: false,
        shouldNotPromptAboutLeaving: true,
      };
    },

    [types.RECEIVE_ESTIMATED_REFUNDS]: (state, { error, payload }) => {
      if (error) {
        return {
          ...state,
          hasFailedToReceiveEstimatedRefund: true,
        };
      }

      return {
        ...state,
        estimatedRefunds: payload,
      };
    },
  },
  {
    returnableItems: {},
    attemptedReturnNoReviewMultiParcel: false,
    bookReturnErrorCount: 0,
    bookedReturn: null,
    createReturnAttempts: {},
    createReturnFatalError: false,
    createReturnMaxTries: 3,
    creatingReturnFor: null,
    dropOffErrorCount: 0,
    dropOffResults: {
      providers: [],
      stores: [],
    },
    dropOffSearchAddress: {
      countryCode: "",
      formattedAddress: "",
    },
    fatalReasonsError: false,
    fatalReferenceError: false,
    fatalReturnableItemsError: false,
    hasAttemptedToPlaceReturn: false,
    hasBeenReviewed: false,
    isBookingReturn: false,
    shouldNotPromptAboutLeaving: false,
    noItemsError: false,
    noPlacesFound: false,
    openReturnForms: [],
    places: [],
    placesErrorCount: 0,
    reasonsLoaded: false,
    referenceLoaded: false,
    returnableItemsLoaded: false,
    requestingDropOffPoints: false,
    requestingPlaces: false,
    returningItems: {},
    returnLocation: null,
    returnReasons: [],
    returnReference: null,
    returnChargeMessage: {},
    returnChargeMessageLoaded: false,
    fatalReturnChargeError: false,
    hasFailedToReceiveEstimatedRefund: false,
    estimatedRefunds: null,
  }
);

export default reducer;
