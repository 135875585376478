import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import Icon from "@components/picture-icon";

import { useThemeContext } from "@context/ThemeContext";

import baseStyles from "../../../../index.css";
import { StatusTag } from "../../status-tag";
import { formatHeaderTreatment } from "../../utilities";

import styles from "./index.css";

export interface HeroProps {
  imageUrl: string;
  title: string;
  className?: string;
  appExclusive?: boolean;
  lockedBenefit?: boolean;
  benefitTier?: number;
}

export const Hero = ({
  imageUrl,
  title,
  className,
  appExclusive,
  lockedBenefit,
  benefitTier,
}: HeroProps) => {
  const { isDarkMode } = useThemeContext();
  return (
    <div
      style={{
        backgroundImage: lockedBenefit ? "" : `url(${imageUrl})`,
        backgroundSize: "cover",
      }}
      className={classNames(styles.wrapper, className, {
        [styles.lockedBenefit]: lockedBenefit,
        [styles.tier2]: lockedBenefit && benefitTier === 2,
        [styles.tier3]: lockedBenefit && benefitTier === 3,
        [styles.tier4]: lockedBenefit && benefitTier === 4,
      })}
    >
      {!lockedBenefit && appExclusive && (
        <div className={styles.tagWrapper}>
          <StatusTag tag="ma_web_loyalty_hub_app_exclusive" />
        </div>
      )}
      <div className={styles.headerWrapper}>
        <h1>{formatHeaderTreatment(title, false)}</h1>
        <span
          className={classNames(styles.decoration, {
            [baseStyles.darkWrapper]: isDarkMode,
          })}
        />
      </div>
      {lockedBenefit && (
        <Icon className={styles.padlockIcon} id="padlockFilled" />
      )}
    </div>
  );
};

Hero.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  appExclusive: PropTypes.bool,
  lockedBenefit: PropTypes.bool,
  benefitTier: PropTypes.number,
};

Hero.defaultProps = {
  className: undefined,
  appExclusive: false,
};
