import { connect } from "react-redux";

import { getAssetsLocation } from "@state/application/selectors";

import CountryFlagIcon from "./component";

const mapStateToProps = (state) => ({
  assetsLocation: getAssetsLocation(state),
});

export default connect(mapStateToProps)(CountryFlagIcon);
