import { getIdentityState } from "@state/application/selectors";
import { hasLoaded } from "@state/loyalty/selectors";
import { hasLoaded as hasPremierSubscriptionsLoaded } from "@state/premier/selectors";
import { hasLoaded as getSubscriptionOptionsLoaded } from "@state/subscription-options/selectors";

export const getPageLoadStatus = (state) => {
  const pathname = window.location.pathname;
  const isOrderHistoryPage = new RegExp(/^\/my-account\/orders\/?$/).test(
    pathname
  );
  const isOrderDetailsPage = new RegExp(
    /^\/my-account\/orders\/[0-9a-z]{1,}\/?$/i
  ).test(pathname);

  if (isOrderDetailsPage) {
    return {
      loaded: getIdentityState(state).loaded && state.orders.loadedDetails,
      pageId: "orderdetails",
    };
  }

  if (isOrderHistoryPage) {
    return {
      loaded: getIdentityState(state).loaded && state.orders.loaded,
      pageId: "orderhistory",
    };
  }

  if (pathname.includes("premier-delivery")) {
    return {
      loaded:
        getIdentityState(state).loaded &&
        getSubscriptionOptionsLoaded(state) &&
        hasPremierSubscriptionsLoaded(state),
      pageId: "premier",
    };
  }

  const isLoyaltyHubUrl = new RegExp(
    /^(\/my-account)?\/asos-world\/?(?![^/])/i
  ).test(pathname);
  const isBenefitDetailsUrl = new RegExp(
    /^(\/my-account)?\/asos-world\/[^/]+\/?$/i
  ).test(pathname);
  if (isBenefitDetailsUrl) {
    return {
      pageId: "benefit details",
      loaded: hasLoaded(state),
    };
  }
  if (isLoyaltyHubUrl) {
    return {
      pageId: "loyalty hub landing page",
      loaded: hasLoaded(state),
    };
  }

  return { loaded: getIdentityState(state).loaded };
};

export const getIdTokenExpiry = () => {
  const storageValue = localStorage.getItem("Asos.TokenManager.token");
  if (!storageValue) return null;

  const parsedStorage = JSON.parse(storageValue);
  return parsedStorage.expires_at;
};
