import React, { createContext, useContext } from "react";

interface BaseThemeContext {
  isDarkMode: boolean;
}
export const ThemeContext = createContext<BaseThemeContext>(null);

export interface ThemeContextProviderProps extends BaseThemeContext {
  children: React.ReactNode;
}

export const ThemeContextProvider = ({
  isDarkMode,
  children,
}: ThemeContextProviderProps) => {
  const value = {
    isDarkMode,
  };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
