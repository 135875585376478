import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageContainer from "@components/layout/page-container";
import Page from "@components/page";
import PageHeader from "@components/page-header/component";

import { useBundle } from "@hooks/useBundle";

import { clearApiErrors } from "@state/application/actions";
import { appLoaded } from "@state/application/selectors";
import { getCommunicationPreferences } from "@state/communication-preferences/actions";

const pageNameKey = "ma_web_communicationpreferences_title";

const bundleLoader = () =>
  import(/* webpackChunkName: 'communication-prefs' */ "./preference-form");

export const getFormChannels = (preferences) =>
  preferences.map((preference) =>
    preference.channels.reduce(
      (acc, channel) => ({
        ...acc,
        [channel]: preference.customerChannels.includes(channel),
      }),
      {
        preferenceId: preference.preferenceId,
        channels: preference.channels,
      }
    )
  );

export const getFormValues = (preferences) => {
  return getFormChannels(preferences).reduce(
    (acc, channel) => ({
      ...acc,
      [channel.preferenceId]: channel,
    }),
    {
      preferenceIds: preferences.map(({ preferenceId }) => preferenceId),
    }
  );
};

export interface Preference {
  title: string;
  summary: string;
  imageUrl: string;
  preferenceId: string;
  consentStatus: string;
  channels: string[];
  customerChannels: string[];
}

interface CommunicationPreferencesStateProps {
  communicationPreferences: {
    preferencesLoaded: boolean;
    fatalError: boolean;
    apiError: boolean;
    preferences: Preference[];
  };
  application: {
    loaded: boolean;
    isLeaving: boolean;
  };
}

const CommunicationPreferences = () => {
  const dispatch = useDispatch();

  const { bundle: PreferenceForm, fatalError: bundleError } =
    useBundle(bundleLoader);

  const {
    preferencesLoaded: loaded,
    fatalError,
    isLeaving,
    hasAppLoaded,
    initialValues,
  } = useSelector((state: CommunicationPreferencesStateProps) => {
    return {
      preferencesLoaded:
        !state.communicationPreferences.apiError &&
        state.communicationPreferences.preferencesLoaded,
      fatalError: state.communicationPreferences.fatalError,
      isLeaving: state.application.isLeaving,
      hasAppLoaded: appLoaded(state),
      initialValues: getFormValues(state.communicationPreferences.preferences),
    };
  });

  useEffect(() => {
    dispatch(clearApiErrors());
    dispatch(getCommunicationPreferences());
  }, [getCommunicationPreferences, hasAppLoaded]);

  const pageReady = PreferenceForm && initialValues;

  return (
    <Page
      loaded={loaded || isLeaving}
      pageHeader={
        <PageContainer>
          <PageHeader
            dataAutoId="ContactPreferences"
            hasBottomPadding
            icon="communication"
            title={pageNameKey}
          />
        </PageContainer>
      }
      pageId="contact-preferences"
      pageTitleKey={pageNameKey}
      showErrorSection={fatalError || bundleError}
    >
      {pageReady && (
        <PreferenceForm
          initialValues={initialValues}
          loaded={loaded}
          disabled={!loaded}
        />
      )}
    </Page>
  );
};

export default CommunicationPreferences;
