import React from "react";

import { getParamsFromUrlState } from "@utils/url";

export const formatHeaderTreatment = (
  stringToFormat: string,
  includeDecorations: boolean = true
) => {
  const textArray = stringToFormat?.split(" ");
  const third = Math.ceil(textArray.length / 3);
  return textArray.flatMap((word: string, i: number) => {
    if (i + 1 === third && includeDecorations) {
      return [
        word,
        <React.Fragment key={`decoration-${i}`}>
          <br />
          {includeDecorations && <span />}
          <br />
        </React.Fragment>,
      ];
    } else if (
      (i + 1 !== textArray.length &&
        i + 1 === third * 2 &&
        textArray.length < 5) ||
      (i + 1 === third && !includeDecorations)
    ) {
      return [
        word,
        <React.Fragment key={`decoration-${i}`}>
          {includeDecorations && <span />}
          <br />
        </React.Fragment>,
      ];
    }
    return `${word} `;
  });
};

export const isNativeApp = () => {
  const params = new URLSearchParams(window.location.search);
  const appPlatform = params.get("app_platform");
  const appPlatforms = ["ios", "android"];
  return appPlatforms.includes(appPlatform);
};

export const getHighestPriorityTag = (tags: Array<string>, supportedTags) => {
  if (tags?.length === 0) {
    return "";
  }

  if (tags.length < 2) {
    return supportedTags[tags[0]]?.shouldDisplay ? supportedTags[tags[0]] : "";
  } else {
    return supportedTags[
      tags
        .sort((a, b) => supportedTags[a]?.priority - supportedTags[b]?.priority)
        .find((tag) => supportedTags[tag]?.shouldDisplay)
    ];
  }
};

export const isWebView = () => {
  return (
    isNativeApp() ||
    /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent) ||
    /Version\/[\d.]+.*Chrome\/[\d.]+ Mobile/i.test(navigator.userAgent)
  );
};

export const isLoyaltyHub = () => {
  return window?.location.href.includes("asos-world");
};

export const isDarkMode = () => {
  const params = new URLSearchParams(window.location.search);
  const darkModeParam = params.get("dark_mode");
  let isDarkMode = darkModeParam && darkModeParam === "true";
  if (isDarkMode === null) {
    isDarkMode = getParamsFromUrlState("dark_mode");
  }
  return isWebView() && isLoyaltyHub() && isDarkMode;
};

export const setBodyDark = (isDarkMode) => {
  if (isDarkMode) {
    document.body.style.setProperty("background-color", "#000");
  }
};
