import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getFormSubmitErrors, getFormSyncErrors } from "redux-form";

import { RootState } from "src/client";

import { CardWithHeading } from "@asosteam/asos-web-component-library-card-with-heading";
import {
  CardSection,
  CardList,
  Card,
} from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";
import { Paragraph } from "@asosteam/asos-web-component-library-paragraph";
import { LONDON_2 } from "@asosteam/asos-web-component-library-typography";

import CheckboxWithError from "@components/checkbox-with-error";
import Prompt from "@components/prompt";
import RetryErrorWrapper from "@components/retry-error-wrapper";
import ReturnTotal from "@components/return-total";
import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { getDeliveryAddressForOrderReference } from "@state/orders/selectors";
import {
  closeReturnForm,
  openReturnForm,
  placeReturn as placeReturnAction,
  getReturnChargeMessages,
  toggleReviewReturn,
  resetReturnLocation,
  resetMultiParcelDialogError,
  attemptReturnBooking,
  setMultiParcelDialogError,
  setNoItemsError,
} from "@state/returns/actions";
import {
  getBookReturnError,
  getIfAttemptedToPlaceReturn,
  getReturnMultiParcelUserReviewedBannerStatus,
  getIfBookingReturn,
  getOpenReturnForms,
  getReturnableItems,
  getReturnItems,
  getReturnLocation,
  getReturnChargeMessage,
  getReturnReasons as getReturnReasonsFromState,
  hasItemSelectedForReturn as hasItemSelectedForReturnCall,
} from "@state/returns/selectors";

import { scrollErrorIntoView } from "@utils/scroll-into-view";
import { totalQuantity } from "@utils/totalQuantity";

import { Button } from "../../../../components/button";
import MultiParcelHelpModal from "../multi-parcel-help-modal";
import ReturnItems from "../return-items";
import ReturnMethod from "../return-method";
import ReturnsWarningMessage from "../returns-warning-message";
import { ReturnSignpost } from "./return-signpost";

import styles from "./index.css";

export const getItemsTotal = (selectedItems, openReturnForms) => {
  const itemQuantity = openReturnForms.map((key) => {
    const quantity =
      selectedItems[key] && selectedItems[key].reasonId
        ? selectedItems[key].quantity
        : 0;

    return quantity;
  });

  return totalQuantity(itemQuantity);
};

export interface CreateReturnProps {
  submit: (arg0: string) => void;
}

const CreateReturn = ({ submit }: CreateReturnProps) => {
  const [showMultiParcelHelpModal, setShowMultiParcelHelpModal] =
    useState(false);

  function toggleMultiParcelHelpModal(toggle) {
    setShowMultiParcelHelpModal(toggle);
  }

  const { orderReference } = useParams();
  const dispatch = useDispatch();

  const formatTranslation = useFormatTranslation();

  useEffect(() => {
    (async () => {
      await getReturnChargeMessagesCall();
    })();
  }, []);

  const {
    attemptedReturnNoReviewMultiParcel,
    dropOffStore,
    feeMessage,
    hasAttemptedToPlaceReturn,
    hasBeenReviewed,
    hasItemFormErrors,
    hasItemSelectedForReturn,
    isBookingReturn,
    shouldNotPromptAboutLeaving,
    noItemsError,
    openReturnForms,
    orderDeliveryAddress,
    placeReturnError,
    returnableItems,
    returningItems,
    returnReasons,
    showMultiParcelDialog,
    postPurchaseWarningMessage,
    postPurchaseChargeInformationMessage,
  } = useSelector((state: RootState) => {
    const returningItems = getReturnItems(state);
    const selectedReturningItems = Object.values(returningItems).filter(
      ({ isReturning }) => isReturning
    );
    const selectedReturningItemsQuantity = selectedReturningItems.reduce(
      (totalQuantity, { quantity }) => totalQuantity + quantity,
      0
    );
    const openReturnForms = getOpenReturnForms(state);
    const hasItemFormErrors = openReturnForms.reduce(
      (alreadyHasError, returnFormId) => {
        const formId = `return-item-${returnFormId}`;
        const syncFormErrors =
          Object.keys(getFormSyncErrors(formId)(state)).length > 0;
        const submitFormErrors =
          Object.keys(getFormSubmitErrors(formId)(state)).length > 0;
        return alreadyHasError || !!(syncFormErrors || submitFormErrors);
      },
      false
    );
    return {
      attemptedReturnNoReviewMultiParcel:
        state.returns.attemptedReturnNoReviewMultiParcel,
      dropOffStore: getReturnLocation(state),
      feeMessage: getReturnChargeMessage(state).feeMessage,
      hasAttemptedToPlaceReturn: getIfAttemptedToPlaceReturn(state),
      hasBeenReviewed: getReturnMultiParcelUserReviewedBannerStatus(state),
      hasItemSelectedForReturn: hasItemSelectedForReturnCall(state),
      hasItemFormErrors,
      isBookingReturn: getIfBookingReturn(state),
      shouldNotPromptAboutLeaving: state.returns.shouldNotPromptAboutLeaving,
      noItemsError: state.returns.noItemsError,
      openReturnForms,
      orderDeliveryAddress:
        getDeliveryAddressForOrderReference(state)(orderReference),
      placeReturnError: getBookReturnError(state),
      returnableItems: getReturnableItems(state),
      returningItems: returningItems,
      returnReasons: getReturnReasonsFromState(state),
      showMultiParcelDialog:
        typeof selectedReturningItemsQuantity === "number" &&
        selectedReturningItemsQuantity > 1,
      postPurchaseWarningMessage:
        getReturnChargeMessage(state).postPurchaseWarningMessage,
      postPurchaseChargeInformationMessage:
        getReturnChargeMessage(state).postPurchaseChargeInformationMessage ||
        getReturnChargeMessage(state).policyDescription,
    };
  });

  const canPlaceReturn =
    hasItemSelectedForReturn &&
    !hasItemFormErrors &&
    !!dropOffStore &&
    (showMultiParcelDialog ? hasBeenReviewed : true);

  const attemptReturnBookingCall = () => dispatch(attemptReturnBooking());
  const closeReturnFormCall = (id) => dispatch(closeReturnForm(id));
  const openReturnFormCall = (id) => dispatch(openReturnForm(id));
  const placeReturnCall = () => dispatch(placeReturnAction());
  const getReturnChargeMessagesCall = () => dispatch(getReturnChargeMessages());
  const resetMultiParcelDialogErrorCall = () =>
    dispatch(resetMultiParcelDialogError());
  const resetReturnLocationCall = () => dispatch(resetReturnLocation());
  const toggleReviewReturnCall = () => dispatch(toggleReviewReturn());
  const setMultiParcelDialogErrorCall = () =>
    dispatch(setMultiParcelDialogError());
  const setNoItemsErrorCall = () => dispatch(setNoItemsError());

  function validateForms() {
    if (showMultiParcelDialog && !hasBeenReviewed) {
      setMultiParcelDialogErrorCall();
    }

    if (openReturnForms.length < 1) {
      setNoItemsErrorCall();
    } else {
      openReturnForms.forEach((variantId) =>
        dispatch(submit(`return-item-${variantId}`))
      );
    }

    setTimeout(scrollErrorIntoView);
  }

  function placeReturn() {
    attemptReturnBookingCall();
    validateForms();

    if (canPlaceReturn) {
      placeReturnCall();
    }
  }

  const totalItemCount = getItemsTotal(returningItems, openReturnForms);

  const reviewedIfMultiParcel = showMultiParcelDialog ? hasBeenReviewed : true;

  const noMethodChosenError =
    !dropOffStore &&
    hasAttemptedToPlaceReturn &&
    hasItemSelectedForReturn &&
    reviewedIfMultiParcel;

  const shouldActivateNextBlocks = showMultiParcelDialog
    ? hasBeenReviewed
    : hasItemSelectedForReturn;

  const hasApiError =
    hasAttemptedToPlaceReturn && placeReturnError && !isBookingReturn;

  return (
    <div>
      {postPurchaseWarningMessage && (
        <ReturnsWarningMessage
          returnsWarningMessage={postPurchaseWarningMessage}
        />
      )}
      <CardList>
        <div id="products">
          <ReturnItems
            closeReturnForm={closeReturnFormCall}
            noItemsError={noItemsError}
            openReturnForm={openReturnFormCall}
            openReturnForms={openReturnForms}
            returnableItems={returnableItems}
            returnReasons={returnReasons}
            getReturnChargeMessages={getReturnChargeMessagesCall}
          />
        </div>

        {showMultiParcelDialog && (
          <CardWithHeading
            heading={formatTranslation("ma_multi_returns_title")}
            isUppercase={true}
          >
            <div className={styles.parcelDialog}>
              {formatTranslation("ma_multi_returns_context")}
            </div>

            <button
              data-auto-id={"learn_more_multi_parcel"}
              className={styles.modalLink}
              onClick={() => toggleMultiParcelHelpModal(true)}
            >
              {formatTranslation("ma_multi_returns_learnmore")}
            </button>

            <CheckboxWithError
              errorId="ma_multi_returns_error_state"
              hasError={attemptedReturnNoReviewMultiParcel}
              checked={hasBeenReviewed}
              input={{
                onChange: toggleReviewReturnCall,
                name: "review_multi_parcel-input",
                ["aria-invalid"]: attemptedReturnNoReviewMultiParcel,
              }}
              text={formatTranslation("ma_multi_returns_acknowledge")}
            />

            <MultiParcelHelpModal
              isOpen={showMultiParcelHelpModal}
              onRequestClose={() => toggleMultiParcelHelpModal(false)}
            />
          </CardWithHeading>
        )}

        <div id="method">
          <ReturnMethod
            active={shouldActivateNextBlocks}
            deliveryAddress={orderDeliveryAddress}
            dropOffStore={dropOffStore}
            noMethodChosenError={noMethodChosenError}
            resetReturnLocation={resetReturnLocationCall}
          />
        </div>
        <div id="total">
          <ReturnTotal
            active={
              shouldActivateNextBlocks || !!postPurchaseChargeInformationMessage
            }
            totalItemCount={totalItemCount}
            feeMessage={feeMessage}
            postPurchaseChargeInformationMessage={
              postPurchaseChargeInformationMessage
            }
          />
        </div>

        <Card>
          <NotificationBar type="alert">
            <Heading domElement="h2" sizing={LONDON_2} isUppercase={true}>
              {formatTranslation("create_returns_page_title")}
            </Heading>
            <Paragraph sizing="leavesden2">
              <Translation id="create_returns_page_body" />
            </Paragraph>
            <Paragraph
              sizing="leavesden2"
              className={styles.returnsWarningBold}
            >
              <Translation id="create_returns_page_body_bold" />
            </Paragraph>
          </NotificationBar>
        </Card>
      </CardList>
      <div className={styles.center}>
        <CardSection
          hasBottomPadding={false}
          hasLeftPadding={false}
          hasRightPadding={false}
          hasTopPadding={!placeReturnError}
        >
          <RetryErrorWrapper
            overrideAction={placeReturn}
            apiError={hasApiError}
          >
            <div className={styles.buttonWrapper}>
              <Button
                buttonType="primary"
                data-auto-id="place-return"
                onClick={() => {
                  resetMultiParcelDialogErrorCall();
                  window.requestAnimationFrame(placeReturn);
                }}
                type="button"
                isInProgress={isBookingReturn}
              >
                {!isBookingReturn &&
                  formatTranslation("ma_web_orderreturn_primarycta")}
              </Button>
            </div>
          </RetryErrorWrapper>

          <ReturnSignpost formatTranslation={formatTranslation} />
        </CardSection>
      </div>
      <Prompt
        when={openReturnForms.length > 0 && !shouldNotPromptAboutLeaving}
      />
    </div>
  );
};

export default CreateReturn;
