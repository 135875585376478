import React from "react";

import { Heading } from "@asosteam/asos-web-component-library-heading";
import { BARNSLEY_4 } from "@asosteam/asos-web-component-library-typography";

import Layout from "@components/layout";
import PageInfo from "@components/page-info";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./component.css";

const Home = () => {
  const formatTranslation = useFormatTranslation();

  return (
    <Layout
      contentFlex
      data-auto-id="home"
      noMainContent
      showSmallNavigationOnly
      isHome
    >
      <PageInfo title="ma_web_home_title" meta={[]} />
      <div className={styles.container}>
        <div className={styles.greeting}>
          <Heading
            sizing={BARNSLEY_4}
            domElement="h3"
            className={styles.heroContainer}
            isUppercase={true}
          >
            {formatTranslation("ma_web_home_greeting")
              .split("\n")
              .map((line) => (
                <span className={styles.heroText} key={line}>
                  {line}
                </span>
              ))}
          </Heading>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
