export const slugs = {
  COM: "/customer-care/returns-refunds/how-do-i-return-something-to-you-from-the-uk/",
  US: "/customer-care/returns-refunds/how-do-i-return-something-to-you-from-the-us/",
  AU: "/customer-care/returns-refunds/how-can-i-return-something-to-you-from-australia/",
  FR: "/service-client/retours-remboursements/comment-retourner-un-article-depuis-la-france-metropolitaine-/",
  IT: "/assistenza-clienti/resi-rimborsi/come-posso-restituire-un-articolo/",
  DE: "/kundenservice/ruecksendungen-rueckerstattungen/wie-schicke-ich-meine-bestellung-aus-deutschland-zuruck/",
  ES: "/atencion-al-cliente/devoluciones-reembolsos/como-hago-una-devolucion/",
  RU: "/customer-care/returns-refunds/how-do-i-return-something-to-you-from-europe/",
  SE: "/kundservice/returer-aterbetalningar/hur-returnerar-jag-nagot-till-er-fran-sverige/",
  NL: "/klantenservice/retouren-terugbetalingen/hoe-stuur-ik-mijn-bestelling-terug-vanuit-nederland/",
  DK: "/kundeservice/returnering-refundering/hvordan-returnerer-jeg-noget-til-jer-fra-danmark/",
  PL: "/obsluga-klienta/zwroty-towaru-lub-srodkow/jak-dokonać-zwrotu-zamówienia-z-polski/",
  ROW: "/customer-care/returns-refunds/how-do-i-return-something-to-you/",
  IE: "/customer-care/returns-refunds/how-do-i-return-something-to-you-from-europe/",
};

export const generateUrl = (countryCode, storeId, siteroot) => {
  if (countryCode === "IE") {
    return `${siteroot}${slugs[countryCode]}`;
  }

  if (slugs[storeId]) {
    return `${siteroot}${slugs[storeId]}`;
  }

  return `${siteroot}${slugs["ROW"]}`;
};
