import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import Icon from "@components/picture-icon";
import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import styles from "./index.css";

export interface TierBadgeProps {
  tierId: number;
  size?: string;
  isDarkMode: boolean;
}

export const TierBadge = ({ tierId, size, isDarkMode }: TierBadgeProps) => {
  const tierBadgeClasses = classNames(styles.wrapper, {
    [styles.small]: size === "small",
    [styles.medium]: size === "medium",
    [styles.dark]: isDarkMode,
    [styles.contrastBorder]: tierId === 4,
  });
  const formatTranslation = useFormatTranslation();
  return (
    <div className={tierBadgeClasses}>
      <Icon
        id={`loyaltyTier${tierId}`}
        alt={`${formatTranslation("ma_web_loyalty_hub_tier")} ${tierId}`}
      />
      <Translation id={`ma_web_loyalty_hub_tier_${tierId}`} />
    </div>
  );
};

TierBadge.propTypes = {
  tierId: PropTypes.number.isRequired,
  size: PropTypes.string,
  isDarkMode: PropTypes.bool,
};

TierBadge.defaultProps = {
  size: "small",
};
