import React from "react";

import ButtonGroup from "@components/button-group";
import Modal from "@components/modal";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { Button } from "../../../../../components/button";

import styles from "./index.css";

export interface VoucherRestrictionModalProps {
  isOpen: boolean;
  onAccept: () => void;
}

const VoucherRestrictionModal = ({
  isOpen,
  onAccept,
}: VoucherRestrictionModalProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <Modal
      contentLabel={formatTranslation(
        "ma_web_giftcardsandvouchers_restrictionmodal_content"
      )}
      isOpen={isOpen}
      onRequestClose={onAccept}
      title={formatTranslation(
        "ma_web_giftcardsandvouchers_restrictionmodal_title"
      )}
      padContent
      type="center"
    >
      <div data-auto-id="VoucherRestrictionModal">
        <div className={styles.content}>
          {formatTranslation(
            "ma_web_giftcardsandvouchers_restrictionmodal_content"
          )}
        </div>
        <ButtonGroup flexDisplay centerContent>
          <Button
            onClick={onAccept}
            data-auto-id="DismissVoucherRestrictionModal"
            type="button"
          >
            {formatTranslation(
              "ma_web_giftcardsandvouchers_restrictionmodal_buttonlabel"
            )}
          </Button>
        </ButtonGroup>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={formatTranslation(
            "ma_web_giftcardsandvouchers_restrictionmodal_helplink"
          )}
          className={styles.helpLink}
        >
          {formatTranslation(
            "ma_web_giftcardsandvouchers_restrictionmodal_helplabel"
          )}
        </a>
      </div>
    </Modal>
  );
};

export default VoucherRestrictionModal;
