import { handleActions } from "redux-actions";

import { CLEAR_API_ERRORS } from "../application/types";
import * as types from "./types";

const reducer = handleActions(
  {
    [types.API_ERROR]: (state, { payload }) => {
      const { maxRetries, tries } = state;
      const { errorType } = payload;
      if (tries >= maxRetries) {
        return {
          ...state,
          apiError: false,
          fatalError: true,
          loaded: true,
          tries: 0,
        };
      }
      if (errorType === "optInError") {
        return {
          ...state,
          apiError: false,
          fatalError: false,
          loaded: true,
          tries: tries + 1,
          optInError: true,
        };
      }
      return {
        ...state,
        apiError: true,
        fatalError: false,
        loaded: true,
        tries: tries + 1,
      };
    },
    [CLEAR_API_ERRORS]: (state) => ({
      ...state,
      fatalError: false,
      apiError: false,
      optInError: false,
      tries: 0,
    }),
    [types.GET_LOYALTY_DATA]: (state) => ({
      ...state,
      apiError: false,
      loaded: false,
      tries: 0,
    }),
    [types.SET_LOYALTY_DATA]: (state, { payload }) => ({
      ...state,
      fatalError: false,
      loaded: true,
      loyaltyStatus: payload,
    }),
    [types.TOGGLE_IS_FIRST_VIEW]: (state, { payload }) => ({
      ...state,
      firstView: payload,
    }),
  },
  {
    apiError: false,
    tries: 0,
    maxRetries: 2,
    fatalError: false,
    loaded: false,
    firstView: false,
    optInError: false,
  }
);

export default reducer;
