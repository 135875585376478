import request from "asos.myaccount.request";

import { getKeyStoreDataversion } from "../../document-handlers";

const LOYALTY_ENDPOINT = "customer/loyalty/v1/customers/me";
const LOYALTY_OPT_IN_ENDPOINT = "customer/loyalty/v1/customers/me/consent";

export const fetchLoyaltyStatus = (
  lang,
  country,
  identity,
  requestFn = request
) => {
  const keystoredataversion = getKeyStoreDataversion();
  return requestFn(LOYALTY_ENDPOINT, identity, {
    query: { keystoredataversion, lang, country },
  });
};

export const customerLoyaltyOptIn = (
  lang,
  country,
  identity,
  requestFn = request
) => {
  return requestFn(LOYALTY_OPT_IN_ENDPOINT, identity, {
    body: {
      consentStatus: "OptedIn",
      country: country,
      lang: lang,
    },
    headers: {
      "Content-Type": "application/json",
    },
    method: "PATCH",
  });
};
