import React from "react";

import classNames from "classnames";

import { Translation } from "@components/translation";

import { mainId } from "@configuration/application";

import baseStyles from "../../index.css";

import styles from "./index.css";

type SkipToContentProps = {
  id?: string;
  skipToId?: (id: string) => void;
  isDarkMode: boolean;
};

const replaceHistoryStateWithoutStateChange = (hash, windowHistory) => {
  windowHistory.replaceState({}, "", `#${hash}`);
};

export const skipToId = (id, windowHistory = window.history) => {
  replaceHistoryStateWithoutStateChange(id, windowHistory);
};

const SkipToContent = ({
  isDarkMode = false,
  id = mainId,
  skipToId: skipToIdFn = skipToId,
}: SkipToContentProps) => (
  <a
    className={classNames(styles.banner, {
      [baseStyles.darkWrapper]: isDarkMode,
    })}
    href={`#${id}`}
    onClick={() => {
      skipToIdFn(id);
    }}
  >
    <Translation id="ma_web_accessibility_skipto" />
  </a>
);

export default SkipToContent;
