import {
  getApplicationVersion,
  getKeyStoreDataversion,
  getPageCountry,
  getPageECommerceStore,
  getPageLanguage,
  getPagePlatform,
} from "../document-handlers";

type BaseAttributes = {
  platform: string;
  storeId: string;
  browseCountry: string;
  keyStoreDataversion: string;
  language: string;
  applicationVersion: string;
  checkoutOrigin: string;
};

type AuthAttributes = {
  isNewCustomer?: string;
  customerIdentifier?: string;
};

type HandledAttributes = BaseAttributes & AuthAttributes;

//@ts-expect-error will be on window.newrelic
type AdditionalAttributes = Record<string, window.newrelic.SimpleType> &
  Partial<Record<keyof HandledAttributes, never>>;

const eCommerceStore = getPageECommerceStore();
const countryCode = getPageCountry();
const language = getPageLanguage();
const platform = getPagePlatform();
const keyStoreDataversion = getKeyStoreDataversion();
const version = getApplicationVersion();

export const buildAttributes = (
  additionalAttributes: AdditionalAttributes = {}
  //@ts-expect-error will be on window.newrelic
): Record<string, window.newrelic.SimpleType> => {
  return {
    platform,
    storeId: eCommerceStore,
    browseCountry: countryCode,
    keyStoreDataversion: keyStoreDataversion,
    language: language,
    applicationVersion: version,
    ...additionalAttributes,
  };
};

export const logError = (
  error: Parameters<typeof window.newrelic.noticeError>[0],
  additionalAttributes?: Parameters<typeof buildAttributes>[0]
) => {
  window.newrelic?.noticeError(error, buildAttributes(additionalAttributes));
};
