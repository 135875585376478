import React from "react";
import { Link } from "react-router-dom";

import classnames from "classnames";

import Logo from "@asosteam/asos-web-component-library-svg-icons/lib/logo.svg";

import { GridColumn, GridRow } from "@components/grid";
import ContentWrapper from "@components/layout/content-wrapper";
import SiteSeal from "@components/site-seal";
import { SmartLink } from "@components/smart-link";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import { isWebView } from "../../app/loyalty-hub/utilities";
import LogoTopshop from "../../styles/topshop/logo.svg";

import styles from "./index.css";

export interface HeaderProps {
  digiCertSealCode?: string;
  language?: string;
  isHome: boolean;
  shop: string;
}

const LogoImage: React.FC<{ shop: string }> = ({ shop }) => {
  const logo = shop === "topshop" ? LogoTopshop : Logo;
  const title = shop === "topshop" ? "Topshop" : "ASOS";
  return <img alt={title} src={logo} className={styles.asosLogo} />;
};

const Header = ({ digiCertSealCode, language, isHome, shop }: HeaderProps) => {
  const formatTranslation = useFormatTranslation();

  return (
    <header
      className={classnames(styles.header, {
        [styles.isLoyaltyWebview]:
          isWebView() && window.location.href.includes("asos-world"),
      })}
      role="banner"
    >
      <ContentWrapper hasPaddingSmall hasPaddingMedium>
        <GridRow>
          <GridColumn smallSize={3} mediumSize={3} largeSize={2}>
            <div className={styles.columnContent}>
              <SmartLink
                className={styles.columnContentInner}
                to="ExternalLink.HomePage"
                newWindow={false}
              >
                <LogoImage shop={shop} />
              </SmartLink>
            </div>
          </GridColumn>
          <GridColumn
            smallSize={6}
            mediumSize={6}
            largeSize={8}
            className={styles.titleColumn}
          >
            <div className={styles.columnContent}>
              <div
                className={classnames(
                  styles.columnContentInner,
                  styles.columnContentInnerHeader
                )}
              >
                {isHome ? (
                  <h1 className={styles.title}>
                    {formatTranslation("ma_web_header_title")}
                  </h1>
                ) : (
                  <Link to="/" className={styles.titleLink}>
                    <h1 className={styles.title}>
                      {formatTranslation("ma_web_header_title")}
                    </h1>
                  </Link>
                )}
              </div>
            </div>
          </GridColumn>
          <GridColumn smallSize={3} mediumSize={3} largeSize={2}>
            <div
              className={classnames(styles.columnContent, styles.vendorArea)}
            >
              <SiteSeal
                language={language}
                digiCertSealCode={digiCertSealCode}
              />
            </div>
          </GridColumn>
        </GridRow>
      </ContentWrapper>
    </header>
  );
};

export default Header;
