import { getYear, parseISO } from "date-fns";

const getUser = (state) => state.user;

export const getEmailAddress = (state) => getUser(state).emailAddress;

export const getFirstName = (state) => getUser(state).firstName;

export const getLastName = (state) => getUser(state).lastName;

export const getGender = (state) => getUser(state).gender;

export const getIfDetailsErrored = (state) => getUser(state).fatalError;

export const getIfDetailsLoaded = (state) => getUser(state).loaded;

export const getLinkedAccounts = (state) => getUser(state).linkedAccounts;

export const hasLoaded = (state) => getUser(state).loaded;

export const getDateOfBirth = (state) => getUser(state).dateOfBirth;

// Handle iOS case where DOB is not required and defaults to 1900-01-01
export const hasValidDateOfBirth = (state) => {
  const dateOfBirth = getDateOfBirth(state);
  if (dateOfBirth) {
    const dDateOfBirth = parseISO(dateOfBirth);
    const year = getYear(dDateOfBirth);
    const isDateValid = year >= getYear(new Date()) - 100;
    return isDateValid;
  }
  return false;
};

export const getHomeCountry = (state) => getUser(state).homeCountry;
