import { handleActions } from "redux-actions";

import {
  APP_STARTUP_ERROR,
  DISMISS_NOTIFICATION,
  FINISH_STARTUP_LOADING,
  FINISH_USER_LEAVING,
  REQUEST_NOTIFICATION,
  RESET_MENU_CLICKED,
  ROUTER_LOCATION_CHANGE,
  SET_MENU_CLICKED,
  START_USER_LEAVING,
} from "./types";

export const getDefaultState = () => ({
  appStartupError: false,
  asosTestEnv: "",
  config: {
    digiCertSealCode: "",
    entryPoint: "",
    isReturnsEnabled: false,
    permittedPaymentOptions: [],
  },
  country: "",
  currency: "",
  shop: "",
  eCommerceStore: "",
  isLeaving: false,
  language: "",
  loaded: false,
  mapsKey: "",
  nextId: 1,
  notification: null,
  platform: "",
  showChrome: true,
  visitedPages: [],
  menuClicked: false,
});

const reducer = handleActions(
  {
    [APP_STARTUP_ERROR]: (state) => ({
      ...state,
      appStartupError: true,
    }),
    [FINISH_STARTUP_LOADING]: (state) => ({
      ...state,
      loaded: true,
    }),
    [REQUEST_NOTIFICATION]: (state, { payload }) => ({
      ...state,
      nextId: state.nextId + 1,
      notification: {
        id: state.nextId,
        textKey: payload.textKey,
        textValues: payload.textValues,
        type: payload.type,
      },
    }),
    [DISMISS_NOTIFICATION]: (state) => ({
      ...state,
      notification: null,
    }),
    [START_USER_LEAVING]: (state) => ({
      ...state,
      isLeaving: true,
    }),
    [FINISH_USER_LEAVING]: (state) => ({
      ...state,
      isLeaving: false,
    }),
    [ROUTER_LOCATION_CHANGE]: (state, { payload }) => ({
      ...state,
      visitedPages: [...(state.visitedPages || []), payload.location.key],
    }),
    [SET_MENU_CLICKED]: (state) => ({
      ...state,
      menuClicked: true,
    }),
    [RESET_MENU_CLICKED]: (state) => ({
      ...state,
      menuClicked: false,
    }),
  },
  getDefaultState()
);

export default reducer;
