import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import appConfig from "@appConfig";

import usePageLoadAnalytics from "@hooks/analytics/pageLoadHook";

import { getIsOptedIn } from "@state/loyalty/selectors";

import Addresses from "../addresses";
import ChangePassword from "../change-password";
import CommunicationPreferences from "../communication-preferences";
import Error from "../error";
import GiftCardsAndVouchers from "../gift-cards-and-vouchers";
import Home from "../home";
import Loyalty from "../loyalty-hub";
import MyDetails from "../my-details";
import Orders from "../orders";
import PaymentMethods from "../payment-methods";
import Premier from "../premier";
import ActivatingPremier from "../premier/activating-premier";
import PremierActivationError from "../premier/premier-activation-error";
import Returns from "../returns";
import SocialConnect from "../social-connect";

export interface ApplicationProps {
  communicationPreferencesError?: boolean;
  finishUserLeaving: (isSaving: boolean) => void;
  formName?: string;
  hasPreferencesLoaded?: boolean;
  isLeaving: boolean;
  saveFormAndExitModal: (formName: string) => void;
  isPageDataLoaded: boolean;
  pageId?: string;
  trackPageLoadComplete: ({ pageId }: { pageId: string }) => void;
}

export const Application = ({
  isPageDataLoaded,
  pageId = "",
}: ApplicationProps) => {
  usePageLoadAnalytics(pageId, isPageDataLoaded);

  const { loyaltyHubEnabled } = appConfig;
  const isOptedIn = useSelector((state: any) => getIsOptedIn(state));

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/orders/*" element={<Orders />} />
        {loyaltyHubEnabled && isOptedIn && (
          <Route path="/asos-world/*" element={<Loyalty />} />
        )}
        <Route path="/returns/*" element={<Returns />} />
        <Route path="/premier-delivery" element={<Premier />} />
        <Route
          path="/premier-activation-error"
          element={<PremierActivationError />}
        />
        <Route path="/activating-premier" element={<ActivatingPremier />} />
        <Route path="/my-details" element={<MyDetails />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/addresses/*" element={<Addresses />} />
        <Route path="/payment-methods/*" element={<PaymentMethods />} />
        <Route
          path="/contact-preferences"
          element={<CommunicationPreferences />}
        />
        <Route path="/social-accounts" element={<SocialConnect />} />
        <Route
          path="/gift-cards-and-vouchers/*"
          element={<GiftCardsAndVouchers />}
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </div>
  );
};

export interface AppProps extends ApplicationProps {
  appStartupError: () => void;
  authenticateIdentity: () => Promise<boolean>;
  getIdentityProfile: () => Promise<void>;
  getAudienceSegments: () => void;
  getSubscriptionOptions: () => void;
  getPremierSubscriptions: () => void;
  getUserDetails: () => Promise<void>;
  noticeError: (error: Error) => void;
  notifyFinishStartupLoading: () => void;
  login: () => Promise<void>;
  getLoyaltyStatus: () => void;
}

const App = ({
  appStartupError,
  authenticateIdentity,
  communicationPreferencesError = false,
  formName = null,
  hasPreferencesLoaded = false,
  isLeaving,
  finishUserLeaving,
  getIdentityProfile,
  getAudienceSegments,
  getSubscriptionOptions,
  getPremierSubscriptions,
  getUserDetails,
  isPageDataLoaded,
  login,
  noticeError,
  notifyFinishStartupLoading,
  pageId = "",
  saveFormAndExitModal,
  trackPageLoadComplete,
  getLoyaltyStatus,
}: AppProps) => {
  const fetchUserAndSubscriptionDetails = async () => {
    // Synchronous user and subscription options
    // We can render still without subscription options, but we need to await user details.
    await getAudienceSegments();
    getSubscriptionOptions();
    await getUserDetails();
    getPremierSubscriptions();
    await getLoyaltyStatus();
  };

  const fetchIdentityProfile = async () => {
    // fail app startup if identity fails
    try {
      await getIdentityProfile();
    } catch (e) {
      noticeError(e);
      appStartupError();
    }
  };

  const checkIfAuthenticated = async () => {
    try {
      return await authenticateIdentity();
    } catch (e) {
      noticeError(e);
      appStartupError();
    }
    return false;
  };

  useEffect(() => {
    checkIfAuthenticated().then(async (isAuthenticated) => {
      if (isAuthenticated) {
        await Promise.all([
          fetchUserAndSubscriptionDetails(),
          fetchIdentityProfile(),
        ]);
        notifyFinishStartupLoading();
      } else {
        await login();
      }
    });
  }, []);

  return (
    <Application
      communicationPreferencesError={communicationPreferencesError}
      finishUserLeaving={finishUserLeaving}
      formName={formName}
      hasPreferencesLoaded={hasPreferencesLoaded}
      isLeaving={isLeaving}
      saveFormAndExitModal={saveFormAndExitModal}
      isPageDataLoaded={isPageDataLoaded}
      pageId={pageId}
      trackPageLoadComplete={trackPageLoadComplete}
    />
  );
};

export default App;
