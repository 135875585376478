import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";

import { useThemeContext } from "@context/ThemeContext";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import baseStyles from "../../../../index.css";
import EventCarousel from "./event-carousel";

import styles from "./index.css";

const EventLibrary = ({
  eventSchedule,
  title,
  ctaText,
  alternativeCtaText,
  lockedBenefit,
}) => {
  const { isDarkMode } = useThemeContext();
  const [activeTab, setActiveTab] = useState("live");
  const formatTranslation = useFormatTranslation();

  const currentEvents = () => {
    const liveEvents = [];
    const previousEvents = [];
    const maxPreviousEventCount = eventSchedule.maxPreviousEventCount;
    eventSchedule.events.forEach((event) => {
      const maxPreviousDate = new Date();
      maxPreviousDate.setDate(
        maxPreviousDate.getDate() - eventSchedule.maxPreviousEventAge
      );
      const now = new Date();
      if (new Date(event.startDate) < now && new Date(event.endDate) > now) {
        liveEvents.push(event);
      } else if (
        new Date(event.endDate) < now &&
        new Date(event.endDate) > maxPreviousDate
      ) {
        if (previousEvents.length < maxPreviousEventCount) {
          previousEvents.push(event);
        }
      }
    });

    liveEvents.length > 1 &&
      liveEvents.sort(
        (a, b) =>
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );
    previousEvents.length > 1 &&
      previousEvents.sort(
        (a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
      );
    return {
      liveEvents: liveEvents?.length > 0 ? liveEvents : [],
      previousEvents: previousEvents?.length > 0 ? previousEvents : [],
    };
  };

  const { liveEvents, previousEvents } = currentEvents();

  return (
    <>
      {(liveEvents.length > 0 || previousEvents.length > 0) && (
        <div
          className={classNames(styles.container, {
            [baseStyles.darkWrapper]: isDarkMode,
            [styles.dark]: isDarkMode,
            [baseStyles.darkModeAsosLayer1]: isDarkMode,
          })}
        >
          {!lockedBenefit && !isDarkMode && <HorizontalRule />}
          <h1>{formatTranslation(title)}</h1>
          {liveEvents.length === 0 && previousEvents.length > 0 && (
            <EventCarousel
              title={title}
              events={previousEvents}
              tab="Previous"
              buttonText={
                alternativeCtaText && formatTranslation(alternativeCtaText)
              }
              isLive={false}
              shouldShowCta={false}
              lockedBenefit={lockedBenefit}
            />
          )}
          {liveEvents.length > 0 && previousEvents.length === 0 && (
            <EventCarousel
              title={title}
              events={liveEvents}
              tab="Live"
              buttonText={ctaText && formatTranslation(ctaText)}
              isLive={true}
              shouldShowCta={!lockedBenefit}
              lockedBenefit={lockedBenefit}
            />
          )}
          {liveEvents.length > 0 && previousEvents.length > 0 && (
            <div>
              <div className={styles.tabs} role="tablist">
                <button
                  className={classNames(styles.tab, {
                    [styles.active]: activeTab === "live",
                    [styles.inactive]: activeTab !== "live",
                  })}
                  onClick={() => setActiveTab("live")}
                  role="tab"
                  aria-selected={activeTab === "live"}
                  id="live"
                  aria-controls="live-panel"
                >
                  <h3>
                    {formatTranslation("ma_web_loyalty_hub_event_library_live")}
                  </h3>
                </button>
                <button
                  className={classNames(styles.tab, {
                    [styles.active]: activeTab === "previous",
                    [styles.inactive]: activeTab !== "previous",
                  })}
                  onClick={() => setActiveTab("previous")}
                  role="tab"
                  aria-selected={activeTab === "previous"}
                  id="previous"
                  aria-controls="previous-panel"
                >
                  <h3>
                    {formatTranslation(
                      "ma_web_loyalty_hub_event_library_previous"
                    )}
                  </h3>
                </button>
              </div>
              {activeTab === "live" && (
                <div role="tabpanel" aria-labelledby="live" id="live-panel">
                  <EventCarousel
                    title={title}
                    events={liveEvents}
                    tab="Live"
                    buttonText={ctaText && formatTranslation(ctaText)}
                    isLive={true}
                    shouldShowCta={!lockedBenefit}
                    lockedBenefit={lockedBenefit}
                  />
                </div>
              )}
              {activeTab === "previous" && (
                <div
                  role="tabpanel"
                  aria-labelledby="previous"
                  id="previous-panel"
                >
                  <EventCarousel
                    title={title}
                    events={previousEvents}
                    tab="Previous"
                    buttonText={
                      alternativeCtaText &&
                      formatTranslation(alternativeCtaText)
                    }
                    isLive={false}
                    shouldShowCta={false}
                    lockedBenefit={lockedBenefit}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

EventLibrary.propTypes = {
  eventSchedule: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  alternativeCtaText: PropTypes.string,
  lockedBenefit: PropTypes.bool,
};

EventLibrary.defaultProps = {
  eventSchedule: [],
  title: "",
  ctaText: "",
  alternativeCtaText: "",
  lockedBenefit: false,
};

export default EventLibrary;
