import React from "react";

import { Card, CardSection } from "@asosteam/asos-web-component-library-cards";
import { Heading } from "@asosteam/asos-web-component-library-heading";
import { HorizontalRule } from "@asosteam/asos-web-component-library-horizontal-rule";
import { NotificationBar } from "@asosteam/asos-web-component-library-notification-bar";
import { LONDON_2 } from "@asosteam/asos-web-component-library-typography";

import { Translation } from "@components/translation";

import { useFormatTranslation } from "@hooks/useFormatTranslation";

import DropOffForm from "./drop-off-form";
import StoreSummary from "./store-summary";

export interface ReturnMethodProps {
  active: boolean;
  deliveryAddress: {
    countryCode: string;
  };
  dropOffStore?: {
    provider: {
      logoUri: string;
      name: string;
    };
    store: {
      address: {
        address1: string;
        address2: string | null;
        address3: string | null;
        locality: string;
        postalCode: string;
      };
      distanceInMeters: number;
      distanceInMiles: number;
      name: string;
      tradingPeriods: {
        day: string;
        weekDayIndex: number;
        openingHours: string;
      }[];
    };
  };
  noMethodChosenError: boolean;
  resetReturnLocation: () => void;
}

const ReturnMethod = ({
  active,
  deliveryAddress,
  dropOffStore = null,
  noMethodChosenError,
  resetReturnLocation,
}: ReturnMethodProps) => {
  const formatTranslation = useFormatTranslation();
  return (
    <Card collapsed={!active}>
      {noMethodChosenError && (
        <NotificationBar data-auto-id="no-method-selected" type="error">
          <Translation id="ma_web_returns_nomethodselectederror" />
        </NotificationBar>
      )}
      <CardSection collapsed={!active}>
        <Heading sizing={LONDON_2} domElement="h3">
          {formatTranslation(
            "ma_web_orderreturn_returnmethod_title"
          ).toUpperCase()}
        </Heading>
      </CardSection>
      {active && (
        <div>
          <CardSection hasTopPadding={false} hasBottomPadding={false}>
            <HorizontalRule />
          </CardSection>
          <div
            data-heading={formatTranslation(
              "ma_web_orderreturn_returnmethod_dropoff_tabheading"
            )}
            data-auto-id="drop-off"
          >
            {!dropOffStore && (
              <DropOffForm
                countryCode={deliveryAddress.countryCode}
                deliveryAddress={deliveryAddress}
              />
            )}
            {dropOffStore && (
              <StoreSummary
                dataAutoId="selected-drop-off-location"
                provider={dropOffStore.provider}
                resetReturnLocation={resetReturnLocation}
                store={dropOffStore.store}
                countryCode={deliveryAddress.countryCode}
              />
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

export default ReturnMethod;
