import appConfig from "@appConfig";

import { getPageType } from "./getPageNames";

export const sendNewRelicCustomAttributes = () => {
  if (
    sessionStorage.getItem("ContentsquareReplay") &&
    sessionStorage.getItem("ContentsquareSID")
  ) {
    window.newrelic.setCustomAttribute(
      "ContentsquareReplay",
      sessionStorage.getItem("ContentsquareReplay")
    );
    window.newrelic.setCustomAttribute(
      "ContentsquareSID",
      sessionStorage.getItem("ContentsquareSID")
    );
  }
};

export const apiMasks = [
  "https://www.asos.com/api/customer/profile/v2/customers/:customer_id",
  "https://www.asos.com/api/commerce/premier/v1/customers/:customer_id",
  "https://www.asos.com/api/commerce/premier/v1/customers/:customer_id/subscriptions",
  "https://www.asos.com/api/commerce/premier/v1/customers/:customer_id/sign-up-sessions",
  "https://www.asos.com/api/commerce/premier/v1/customers/:customer_id/management-sessions",
  "https://www.asos.com/api/customer/profile/v2/customers/:customer_id/addresses",
  "https://www.asos.com/api/customer/profile/v2/customers/:customer_id/addresses/swap",
  "https://www.asos.com/api/customer/profile/v2/customers/:customer_id/addresses/:address_id",
  "https://www.asos.com/api/customer/preference/v1/preferences/:customer_id",
  "https://www.asos.com/api/commerce/myaccount/v2/customers/:customer_id/orders",
  "https://www.asos.com/api/commerce/myaccount/v2/customers/:customer_id/orders/:order_reference",
  "https://www.asos.com/api/commerce/order/v3/orders/:order_reference/cancelorder",
  "https://www.asos.com/api/customer/identity/v1/identities/:customer_id/password",
  "https://www.asos.com/api/customer/identity/v1/identities/:customer_id/linked-accounts",
  "https://www.asos.com/api/customer/identity/v1/identities/:customer_id/linked-accounts/:provider",
  "https://www.asos.com/api/customer/paymentdetails/v2/customers/:customer_id/paymentdetails",
  "https://www.asos.com/api/customer/paymentdetails/v2/customers/:customer_id/paymentdetails/cards",
  "https://www.asos.com/api/commerce/customerreturns/v2/customers/:customer_id/returns",
  "https://www.asos.com/api/commerce/returnbooking/v2/customers/:customer_id/returns",
  "https://www.asos.com/api/commerce/returnbooking/v2/customers/:customer_id}/returnableitems",
  "https://www.asos.com/api/commerce/customerreturns/v2/customers/:customer_id/returns/:return_reference/ReturnDocuments/AccessToken",
  "https://www.asos.com/api/finance/payments-query/v1/payments/:payment_reference",
  "https://www.asos.com/api/commerce/returnbooking/v2/:return_reference",
];

export const initContentSquare = async ({
  isLoggedIn,
}: {
  isLoggedIn: boolean;
}) => {
  window._uxa = window._uxa || [];

  const customVarObj = {
    1: {
      name: "countryCode",
      value: appConfig.analytics.browseCountry,
    },
    3: {
      name: "asosDeviceType",
      value: appConfig.analytics.platform,
    },
    4: {
      name: "storeCode",
      value: appConfig.analytics.storeId,
    },
    9: {
      name: "pageType",
      value: "my-account",
    },
    15: {
      name: "SignedIn",
      value: isLoggedIn,
    },
  };

  for (const key in customVarObj) {
    window._uxa.push([
      "setCustomVariable",
      Number(key),
      customVarObj[key].name,
      customVarObj[key].value,
      3,
    ]);
  }

  apiMasks.forEach((apiMask) => {
    window._uxa.push(["api-errors:maskUrl", apiMask]);
  });

  const pageType = getPageType(window.location.pathname);

  if (typeof window.CS_CONF === "undefined") {
    window._uxa.push([
      "setPath",
      window.location.pathname + window.location.hash.replace("#", "?__"),
    ]);
    const mt = window.document.createElement("script");
    mt.type = "text/javascript";
    mt.async = true;
    mt.setAttribute("nonce", appConfig.nonce);
    mt.src =
      "https://assets.asosservices.com/shared/asos-web-content-square/70973e40718f2.js";
    window.document.getElementsByTagName("head")[0].appendChild(mt);
  } else {
    window._uxa.push([
      "trackPageview",
      `${window.location.pathname}${window.location.hash.replace(
        "#",
        "?__"
      )}?cs-my-account-page=${pageType}`,
    ]);
  }
  window._uxa.push(["afterPageView", sendNewRelicCustomAttributes]);
};
