import request from "asos.myaccount.request";

const BOOK_RETURN_BASE_ENDPOINT =
  "commerce/returnbooking/v2/customers/{customerId}/returns";
const RETURN_REASONS_ENDPOINT = "commerce/returnbooking/v2/returnReasons";
const RETURN_REFERENCE_ENDPOINT = "commerce/returnbooking/v2/returnReference";
const RETURN_DROP_OFF_ENDPOINT =
  "commerce/deliveryoptions/v2/searchdropoffpoints";
const GOOGLE_GEOCODE_ENDPOINT =
  "https://maps.googleapis.com/maps/api/geocode/json";
const RETURN_RETURNABLE_ITEMS_ENDPOINT =
  "commerce/returnbooking/v2/customers/{customerId}/returnableitems";

const RETURN_BOOKING_MESSAGES_ENDPOINT =
  "commerce/returnbooking/v2/charges/messages";

const ESTIMATED_REFUNDS = "selfserve/refundestimate/v1/estimate";

const MAX_STORE_RESULTS = 10;
const RETURN_METHODS = {
  collection: 4,
  dropOff: 3,
};

const getBookReturnEndpoint = (returnReference, language) =>
  `${BOOK_RETURN_BASE_ENDPOINT}/${returnReference}?language=${language}`;

export const bookReturn = (
  returnReference,
  items,
  language,
  returnMethodId,
  returnMethodSpecificFields,
  identity,
  requestFn = request
) =>
  requestFn(getBookReturnEndpoint(returnReference, language), identity, {
    body: JSON.stringify({
      ...returnMethodSpecificFields,
      returnMethodId,
      items,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
  });

export const bookDropOffReturn = (
  returnReference,
  items,
  language,
  dropOffPointId,
  providerId,
  identity,
  bookReturnFn = bookReturn
) => {
  const dropOffSpecificFields = {
    dropOff: {
      dropOffPointId,
    },
    providerId,
  };

  return bookReturnFn(
    returnReference,
    items,
    language,
    RETURN_METHODS.dropOff,
    dropOffSpecificFields,
    identity
  );
};

export const getReturnableItems = (
  selectedOrderReference,
  country,
  identity,
  requestFn = request
) =>
  requestFn(
    `${RETURN_RETURNABLE_ITEMS_ENDPOINT}?selectedOrderReference=${selectedOrderReference}&country=${country}`,
    identity
  );

export const getReturnReasons = (language, requestFn = request) =>
  requestFn(`${RETURN_REASONS_ENDPOINT}?lang=${language}`);

export const getReturnReference = (
  customerId,
  language,
  rmaRequestDateTime,
  identity,
  requestFn = request
) =>
  requestFn(`${RETURN_REFERENCE_ENDPOINT}?lang=${language}`, identity, {
    body: JSON.stringify({
      customerId,
      date: rmaRequestDateTime,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

export const googlePlacesSearch = (
  address,
  countryCode,
  postalCode,
  mapsKey,
  requestFn = request
) => {
  let countryComponents = `country:${countryCode}`;

  if (countryCode === "GB") {
    countryComponents += "|country:JE|country:GG|country:IM";
  }

  return requestFn(GOOGLE_GEOCODE_ENDPOINT, undefined, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    query: {
      address,
      components: `${countryComponents}${
        postalCode ? "|postal_code:" + postalCode : ""
      }`,
      key: mapsKey,
    },
  });
};

export const getEstimatedRefunds = (
  returnReference,
  items,
  identity,
  requestFn = request
) => {
  return requestFn(ESTIMATED_REFUNDS, identity, {
    body: JSON.stringify({
      returnReference,
      items,
    }),
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getDropOffPoints = (
  latitude,
  longitude,
  country,
  eCommerceStore,
  language,
  requestFn = request
) =>
  requestFn(`${RETURN_DROP_OFF_ENDPOINT}`, undefined, {
    body: JSON.stringify({
      coordinates: {
        latitude,
        longitude,
      },
      country,
      lang: language,
      limit: MAX_STORE_RESULTS,
      store: eCommerceStore,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

export const getReturnChargeMessages = (
  orderReferences,
  rmaRequestDateTime,
  identity,
  requestFn = request
) =>
  requestFn(
    `${RETURN_BOOKING_MESSAGES_ENDPOINT}?orderReferences=${orderReferences}&rmaRequestDateTime=${rmaRequestDateTime}`,
    identity
  );
